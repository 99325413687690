import axios from 'axios';
const SERVER_URL = "https://elabfactor.com";

const testToken = async (data: any) => {
    const ENDPOINT = `${SERVER_URL}/api/recaptcha/validate.php`

    try {
        let response = await axios.post(ENDPOINT, data, {
            headers: {
                "Content-Type" : "multipart/form-data",
            },
        });

        return response
    } catch (e: any) {
        console.log(e.response!.data.message);
    }
}

export {testToken}