import { FC, useRef, useState, useEffect, useContext } from "react"
import ReactLoading from 'react-loading';
import { changeEmailWithCode, requestCode } from "../../libs/verificationCodeController";
import validateEmail from "../../utils/emailValidator";
import FormHook from "../../hooks/InputForm";
import { logout } from "../../libs/authContoller";
import { updatePassword } from "../../libs/profileController";

const ChangePassword: FC<{ active: boolean, setOpen: any }> = ({ active, setOpen }) => {
    const oldPassword = FormHook((e: string) => { return e.length >= 8 })
    const newPassword = FormHook((e: string) => { return e.length >= 8 && confirmPassword.ref.current.value == e })
    const confirmPassword = FormHook((e: string) => { return e.length >= 8 && newPassword.ref.current.value == e })

    const handleChangeButton = () => {
        let check = oldPassword.validationFunction()
        check &= newPassword.validationFunction()
        check &= confirmPassword.validationFunction()
        if (!check)
            return

        const response: any = updatePassword({"oldPass": oldPassword.ref.current.value, "newPass": newPassword.ref.current.value})
        console.log(response)
    }

    if (!active)
        return <></>

    return (
        <div className="bg-black/40 w-screen h-screen fixed top-0 left-0 flex flex-col justify-start sm:justify-center sm:items-center pt-8 sm:pt-16 z-40 overflow-y-auto">
            <div className='w-full sm:max-w-xl px-4 sm:px-6 lg:px-8 pb-8'>
                <div className="bg-white p-4 rounded-xl flex flex-col sm:grid sm:grid-cols-4 gap-2 overflow-hidden relative">
                    <div className="text-2xl font-medium col-span-4">Изменение пароля</div>
                    {/* <div className="mt-2 text-black/60 col-span-4">Данный договор будет отправлено оппоненту для подтверждения</div> */}
                    <div className="mt-2 col-span-4 relative">
                        <div className="col-span-2">
                            <label className="block font-medium leading-6 text-gray-900 mt-2">Старый пароль</label>
                            <input ref={oldPassword.ref} onChange={oldPassword.onchange} type="password" className="mt-2 w-full bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                        </div>
                        <div className="col-span-2">
                            <label className="block font-medium leading-6 text-gray-900 mt-2">Новый пароль</label>
                            <input ref={newPassword.ref} onChange={newPassword.onchange} type="password" className="mt-2 w-full bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                        </div>
                        <div className="col-span-2">
                            <label className="block font-medium leading-6 text-gray-900 mt-2">Повторите пароль</label>
                            <input ref={confirmPassword.ref} onChange={confirmPassword.onchange} type="password" className="mt-2 w-full bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                        </div>
                    </div>
                    <div className="flex flex-row justify-end col-span-4 mt-2 gap-4">
                        <button onClick={() => { setOpen(false) }} className="bg-gray-200 text-black py-2 px-4 rounded-md">Отменить</button>
                        <button onClick={handleChangeButton} className="bg-[#006C93] text-white py-2 px-4 rounded-md">Изменить</button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ChangePassword