import { FC, useEffect } from 'react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { Fragment, useState } from 'react'
import useWindowScrollPosition from '@rehooks/window-scroll-position'
import { randomInt } from 'remirror'
import { NavLink } from 'react-router-dom'

const NavLi: FC<{ isActive: boolean, name: string, url: string }> = ({ isActive, name, url }) => {
    return (
        <NavLink to={url} className={`text-xl sm:text-base ${isActive ? 'text-white py-2 px-4 bg-white/20 rounded-full first-letter:duration-300 cursor-pointer' : 'py-2 px-4 rounded-full text-white/50 bg-transparent hover:bg-white/10 hover:text-white/70 duration-300 cursor-pointer'}`}>{name}</NavLink>
        // <a href={url} className={`text-xl sm:text-base ${isActive ? 'text-white py-2 px-4 bg-white/20 rounded-full first-letter:duration-300 cursor-pointer' : 'py-2 px-4 rounded-full text-white/50 bg-transparent hover:bg-white/10 hover:text-white/70 duration-300 cursor-pointer'}`}>{name}</a>
    )
}

const AppHeader = () => {
    const [isModalOpened, setModalOpen] = useState(false)

    const handleOpenModalMenu = () => {
        setModalOpen(true)
    }

    const handleCloseModalMenu = () => {
        setModalOpen(false)
    }

    return (
        <>
            <div className="w-full border-b border-white/20">
                <div className="max-w-7xl w-full mx-auto flex flex-row justify-between px-4 sm:px-6 lg:px-8 py-3">
                    <div className='flex flex-row justify-between lg:justify-start w-full lg:w-auto'>
                        <div onClick={() => { window.open("/", "_self") }} className='flex flex-row justify-end items-center cursor-pointer'>
                            <div className="left-0 w-10 h-10">
                                <img className="" src="/logo2.svg" alt="" />
                            </div>
                            <div className="text-white ml-4 text-xl flex flex-col justify-center leading-none app-logo notranslate">
                                <div className=''>e-Lab Factor</div>
                                <div className="text-sm text-white/75">Факторинговая компания</div>
                            </div>
                        </div>
                        <div className='py-2 block lg:hidden'>
                            <MenuIcon onClick={handleOpenModalMenu} className='h-8 text-white'></MenuIcon>
                        </div>
                        <div className='flex-row justify-start items-center content-center lg:gap-x-4 hidden lg:flex ml-10'>
                            <NavLi name="Мои контрагенты" isActive={window.location.href.split('/')[4] == 'myclients'} url="/app/myclients"></NavLi>
                            <NavLi name="Поставки" isActive={window.location.href.split('/')[4] == 'deliveries'} url="/app/deliveries"></NavLi>
                            <NavLi name="Календарь платежей" isActive={window.location.href.split('/')[4] == 'calendar'} url="/app/calendar"></NavLi>
                        </div>
                    </div>
                    <div className='flex-row justify-start items-center content-center gap-x-8 hidden lg:flex'>
                        <NavLi name="Личный кабинет" isActive={window.location.href.split('/')[4] == 'profile'} url="/app/profile"></NavLi>
                    </div>
                </div>
            </div>

            <div className={`fixed sm:hidden duration-200 w-full left-0 bg-gradient-to-br from-[#355a79] to-[#1C4B74] ${useWindowScrollPosition({ throttle: 200 }).y < 60 ? "-top-32" : "top-0"}`}>
                <div className="max-w-7xl w-full mx-auto flex flex-row justify-between px-4 sm:px-6 lg:px-8 py-3">
                    <div className='flex flex-row justify-end items-center'>
                        <div className="left-0 w-10 h-10">
                            <img className="" src="/logo2.svg" alt="" />
                        </div>
                        <div className="text-white ml-4 text-xl flex flex-col justify-center leading-none">
                            <div>e-Lab Factor</div>
                            <div className="text-sm text-white/75">Факторинговая компания</div>
                        </div>
                    </div>
                    <div className='py-2 block sm:hidden'>
                        <MenuIcon onClick={handleOpenModalMenu} className='h-8 text-white'></MenuIcon>
                    </div>
                </div>
            </div>

            <div className={`fixed lg:hidden bg-[#11436e] w-full h-full top-0 left-0 flex flex-col items-stretch text-center px-4 pt-2 pb-8 duration-300 overflow-hidden ${isModalOpened ? 'left-0' : " -left-full"}`}>
                <div className='flex flex-col'>
                    <div className='self-end'>
                        <div className='py-2 block lg:hidden'>
                            <XIcon onClick={handleCloseModalMenu} className='h-8 text-white'></XIcon>
                        </div>
                    </div>
                    <div className='flex flex-col gap-2 pt-8'>
                        <NavLi name="Мои контрагенты" isActive={window.location.href.split('/')[4] == 'myclients'} url="/app/myclients"></NavLi>
                        <NavLi name="Поставки" isActive={window.location.href.split('/')[4] == 'deliveries'} url="/app/deliveries"></NavLi>
                        <NavLi name="Календарь платежей" isActive={window.location.href.split('/')[4] == 'calendar'} url="/app/calendar"></NavLi>
                    </div>
                </div>
                <div className='flex flex-col mt-4 pt-4 border-t border-white/20'>
                    <NavLi name="Личный кабинет" isActive={window.location.href.split('/')[4] == 'profile'} url="/app/profile"></NavLi>
                </div>
            </div>
        </>
    )
}

export default AppHeader;