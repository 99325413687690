import { useState } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import { DeliveryContext } from './context/DeliveryContext';
import HomePage from './pages/Home/Home';
import Auth from './pages/auth/Auth';
import PasswordReset from './pages/auth/PasswordReset';
import Calendar from './pages/calendar/Calendar';
import Deliveries from './pages/deliveries/Deliveries';
import MyClients from './pages/myclients/MyClients';
import NotFound from './pages/notFound/NotFound';
import Profile from './pages/profile/Profile';
import RegistrationForm from './pages/registrationForm/RegistrationForm';
import { DateToShortTimestamp } from './utils/dates';

function App() {

  const [data, setDelivery] = useState({
    name: "",
    bin: "",
    client_id: "",
    invoice_files: [],
    factor_files: [],
    act_files: [],
    others_files: [],
    payment_dates: [DateToShortTimestamp(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())],
    delivery_date: DateToShortTimestamp(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
    delivery_cost: 0,
    contract: {},
    comment: ""
  })

  return (
    <>
        <DeliveryContext.Provider value={{ data, setDelivery }} >
          <BrowserRouter>
            <Routes >
              <Route path='/app/auth' element={<Auth />} />
              <Route path='/app/password-reset' element={<PasswordReset />} />
              <Route path='/app/profile' element={<Profile />} />
              <Route path='/app/myclients' element={<MyClients />} />
              <Route path='/app/deliveries' element={<Deliveries />} />
              <Route path='/app/calendar' element={<Calendar />} />
              <Route path='/app/registration' element={<RegistrationForm />} />
              <Route path='/' element={<HomePage />} />
              <Route path='*' element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </DeliveryContext.Provider>
    </>

  );
}

export default App;
