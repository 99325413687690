import { XIcon } from "@heroicons/react/outline"
import { FC, useState } from "react"
import { NavLink } from "react-router-dom"

const NotFound = () => {

    const [isFormOpened, setFormOpen] = useState(false)

    document.body.style.overflow = isFormOpened ? 'hidden' : 'auto'

    return (
        <div className="bg-white min-h-screen">
            <div className="bg-gradient-to-br from-[#355a79] to-[#1C4B74] h-screen flex flex-col">
                <div className="py-4 w-full border-b border-white/20">
                    <div className="w-auto max-w-7xl mx-auto flex flex-row justify-between relative items-center px-4 sm:px-6 lg:px-8">
                        <div className="relative flex flex-row items-center">
                            <NavLink to="/" className='flex flex-row justify-end items-center select-none'>
                                <div className="left-0 w-10 h-10">
                                    <img className="" src="/logo2.svg" alt="" />
                                </div>
                                <div className="text-white ml-4 text-xl flex flex-col justify-center leading-none app-logo notranslate">
                                    <div className=''>e-Lab Factor</div>
                                    <div className="text-sm text-white/75">Факторинговая компания</div>
                                </div>
                            </NavLink>

                        </div>
                        <div>
                            <a href="/app/auth" className="py-2 px-4 font-medium bg-[#f9d370] hover:opacity-90 duration-300 rounded-md">
                                Авторизоваться
                            </a>
                        </div>
                    </div>
                </div>
                <div className="relative flex flex-col flex-auto justify-center">
                    <div className="w-auto max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col items-center mb-32">
                        <h5 className="text-9xl text-white">404</h5>
                        <h6 className="text-3xl text-white/70">Страница не найдена</h6>
                        <a href="/" className=" text-[#f9d370] font-medium duration-300 block mt-8">
                            Вернуться на главную
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound