import { XIcon } from "@heroicons/react/outline"
import { FC, useRef, useState } from "react"
import { NavLink } from "react-router-dom"
import validateEmail from "../../utils/emailValidator"
import { sendForm } from "../../libs/formsController"

const HomePage = () => {

    const [isFormOpened, setFormOpen] = useState(false)

    document.body.style.overflow = isFormOpened ? 'hidden' : 'auto'

    return (
        <div className="bg-white min-h-screen">
            <div className="bg-gradient-to-br from-[#355a79] to-[#1C4B74]">


                <div className="py-4 w-full border-b border-white/20">
                    <div className="w-auto max-w-7xl mx-auto flex flex-row justify-between relative items-center px-4 sm:px-6 lg:px-8">
                        <div className="relative flex flex-row items-center">
                            <NavLink to="/" className='flex flex-row justify-end items-center select-none'>
                                <div className="left-0 w-10 h-10">
                                    <img className="" src="/logo2.svg" alt="" />
                                </div>
                                <div className="text-white ml-4 text-xl flex flex-col justify-center leading-none app-logo notranslate">
                                    <div className=''>e-Lab Factor</div>
                                    <div className="text-sm text-white/75">Факторинговая компания</div>
                                </div>
                            </NavLink>

                        </div>

                        <div>
                            <a href="/app/auth" className="py-3 px-6 font-medium bg-[#f9d370] text-[#12436E] hover:opacity-90 duration-300 rounded-md">
                                Авторизоваться
                            </a>
                        </div>
                    </div>
                </div>
                <div className="pt-24 pb-32 sm:pb-48 relative">
                    <div className="w-auto max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col items-start">
                        <h1 className="text-5xl lg:text-6xl text-white lg:w-1/2 font-medium text-center lg:text-left">
                            Факторинговая компания <span className="whitespace-pre">e-Lab Factor</span>
                        </h1>
                        <h1 className="text-white/90 text-base sm:text-lg lg:w-1/2 mt-2 sm:mt-4 text-center lg:text-left">
                            это новый формат предоставления услуги факторинга, созданный для решения задач Поставщиков и Покупателей, и направленный на оптимизацию управления оборотными средствами компании и администрирование дебиторской задолженности
                        </h1>
                        <a href="/app/registration" className="py-3 px-6 border border-[#f9d370] text-[#f9d370] text-[#12436E] rounded-md mt-8 font-medium hover:bg-[#f9d370] hover:text-[#12436E] duration-300 hidden lg:block">
                            Добавить компанию
                        </a>
                    </div>
                    <div className="absolute w-1/2 h-full pt-20 right-0 bottom-0 flex-row overflow-hidden hidden lg:flex">
                        <img className="ml-16 h-[105%] pointer-events-none object-cover object-left-bottom" src="./pc_mocup_yellow.png" alt="" />
                    </div>
                </div>
            </div>
            <div>
                <div className="w-auto max-w-7xl mx-auto mt-16 gap-8 pb-4 sm:pb-8 flex flex-col sm:flex-row justify-between items-center px-4 sm:px-6 lg:px-8">
                    <h3 className="text-5xl font-medium leading-tight">
                        Что такое <br className="hidden sm:block" /> факторинг?
                    </h3>
                    <h5 className="sm:text-lg sm:w-2/3 mt-0 sm:mt-0">
                        <p>
                            Факторинг
                            – это финансовая услуга, при которой Компания-поставщик может продать свою дебиторскую задолженность Фактору «e-Lab Factor» со скидкой.  Это позволяет Компании получать немедленный денежный поток, а не ждать, пока Покупатели оплатят счета.
                            Фактор также осуществляет услугу по администрированию дебиторской задолженности.
                        </p>
                        {/* – это комплекс финансовых услуг для компаний, которые продают товары и услуги на условиях отсрочки платежа. Поставщик получает финансирование, уступая факторинговой компании право требовать оплату с покупателя. */}
                    </h5>
                </div>
            </div>
            <div className="bg-white pb-16 sm:mt-8">
                {/* <div className="w-full max-w-7xl mx-auto text-black pb-12">
                    <h3 className="text-4xl font-medium text-center">
                        Как работает факторинг
                    </h3>
                </div> */}
                <div className="w-auto max-w-7xl mx-auto flex flex-col lg:flex-row justify-start gap-8 px-4 sm:px-6 lg:px-8">
                    <div className="lg:w-[45%] sm:text-lg">
                        Схема факторинга предполагает 3 основных участника: <br /><br />

                        <ul className="list-disc ml-5 space-y-2">
                            <li className="">
                                <span className="font-semibold">Поставщик</span> — производитель или поставщик продукции; компания, оказывающая услуги.
                            </li>
                            <li className="">
                                <span className="font-semibold">Покупатель</span> — торговые сети, нефтегазовые и металлургические компании, дистрибьюторы, предприятия различных сфер деятельности.
                            </li>
                            <li className="">
                                <span className="font-semibold">e-Lab Factor</span> — факторинговая компания.
                            </li>
                        </ul>
                    </div>
                    <div className="sm:grid sm:grid-cols-5 gap-2 space-y-4 sm:space-y-0">
                        <div className="bg-slate-100 col-span-3 rounded-xl relative overflow-hidden flex flex-row p-6 items-center">
                            <div className="rounded-full text-5xl font-light">1</div>
                            <div className="flex flex-col ml-8 justify-center">
                                <h5 className="text-xl font-medium">Поставка товара (работ, услуг)</h5>
                                <p className="">От Поставщика Покупателю</p>
                            </div>
                        </div>
                        <div className="bg-slate-100 col-span-2 rounded-xl relative overflow-hidden flex flex-row p-6 items-center">
                            <div className="rounded-full text-5xl font-light">2</div>
                            <div className="flex flex-col ml-8 justify-center">
                                <h5 className="text-xl font-medium">Документы</h5>
                                <p className="">От Поставщика к Фактору</p>
                            </div>
                        </div>
                        <div className="bg-slate-100 col-span-2 rounded-xl relative overflow-hidden flex flex-row p-6 items-center">
                            <div className="rounded-full text-5xl font-light">3</div>
                            <div className="flex flex-col ml-8 justify-center">
                                <h5 className="text-xl font-medium">Финансирование</h5>
                                <p className="">Фактором Поставщика сразу после поставки</p>
                            </div>
                        </div>
                        <div className="bg-slate-100 col-span-3 rounded-xl relative overflow-hidden flex flex-row p-6 items-center">
                            <div className="rounded-full text-5xl font-light">4</div>
                            <div className="flex flex-col ml-8 justify-center">
                                <h5 className="text-xl font-medium">Оплата товара (работ, услуг) <span className="whitespace-nowrap">с отсрочкой</span></h5>
                                <p className="">От Покупателя Фактору по истечении срока отсрочки</p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div className="bg-slate-100 pb-16">
                <div className="w-auto max-w-7xl mx-auto pt-14">
                    <h3 className="text-4xl font-medium text-center">
                        Что мы предлагаем
                    </h3>
                </div>
                <div className="w-auto max-w-7xl mx-auto mt-8 flex flex-col lg:grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 px-4 sm:px-6 lg:px-8">
                    <div className="p-8 bg-white rounded-lg">
                        <h5 className="text-xl font-medium">Автоматизированный процесс</h5>
                        <p className="pt-1">Управление дебиторской задолженностью и цепочками поставок, построенное на опыте клиента и формирующее эффективную систему получения платежей.</p>
                    </div>
                    <div className="p-8 bg-white rounded-lg">
                        <h5 className="text-xl font-medium">Финансирование</h5>
                        <p className="pt-1">Финансирование Поставщика под уступку дебиторской задолженности, позволяющее предоставлять отсрочку платежа Покупателю, не снижая объема оборотных средств.</p>
                    </div>
                </div>
            </div>

            <div>
                <div className="w-auto max-w-7xl mx-auto pt-14">
                    <h3 className="text-4xl font-medium text-center">
                        Наши условия финансирования
                    </h3>
                </div>
                <div className="w-auto max-w-7xl mx-auto pb-8 pt-8 px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row gap-8 lg:gap-16 items-start">
                    <div className="flex flex-col gap-4 flex-1">
                        <div className="flex flex-col">
                            <div className="font-medium text-xl">
                                Покупка дебиторской <span className="whitespace-nowrap">задолженности (дисконтирование)</span>
                            </div>
                            <div className="mt-1 sm:text-lg">
                                Покупка неоплаченного долга с выплатой до 95% от суммы дебиторской задолженности (суммы поставок, согласно представленным документам). Дисконтирование решает финансовую задачу по управлению временной стоимости денег: деньги сейчас дороже, чем деньги в будущем.
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <div className="font-medium text-xl">
                                Финансирование
                            </div>
                            <div className="mt-1 sm:text-lg">
                                В данном случае клиент (Поставщик) получает финансирование под уступку дебиторской задолженности, т.е. под залог неоплаченных счетов-фактур
                                до 80% от суммы дебиторской задолженности.
                                Возврат средств производится Поставщиком или Должником напрямую.
                            </div>
                        </div>
                    </div>
                    <div className="bg-[#12436E] rounded-xl px-12 py-8 flex flex-col justify-between text-white items-start self-end lg:w-[32%]">
                        <div className="sm:text-lg font-medium">
                            Если Вы представляете <span className=" whitespace-nowrap">свою цепочку</span> поставок, вы можете получить индивидуальные условия
                        </div>
                        <button onClick={() => { setFormOpen(true) }} className="bg-[#f9d370] text-lg rounded w-full text-center mt-6 py-2 font-medium text-[#12436E] self-end hover:opacity-90 duration-300">
                            Обратиться с вопросом
                        </button>
                    </div>
                </div>
            </div>

            {/* <div>
                <div className="w-auto max-w-7xl mx-auto mt-16 pb-16">
                    <h3 className="text-3xl font-medium">
                        О нас
                    </h3>
                    <h5 className="text-lg mt-6 w-2/3">
                        Наша компания имеет цель и миссию содействия развитию малого бизнеса в Казахстане за счет возможности предоставлять отсрочку покупателям, без формирования кассовых разрывов, используя простую и прозрачную систему документооборота при осуществлении поставок и получении финансирования.
                    </h5>
                </div>
            </div> */}

            <div>
                <div className="w-auto max-w-7xl mx-auto pb-16 pt-16 px-4 sm:px-6 lg:px-8">

                    <div className="flex flex-col lg:grid lg:grid-cols-2 gap-16">
                        <div className="col-span">
                            <h3 className="text-4xl font-medium text-left -mt-2">
                                О нас
                            </h3>
                            <div className="mt-6 sm:text-lg">
                                Наша деятельность направлена на формирование устойчивых и финансово обеспеченных взаимоотношений между Поставщиками и Покупателями <span className="whitespace-nowrap">с целью</span> развития стабильных цепочек поставок.<br /><br />
                                Наша услуга позволяет предоставлять отсрочку Покупателям, <span className="whitespace-nowrap">что положительно</span> сказывается <span className="whitespace-nowrap">на объемах</span> продаж <span className="whitespace-nowrap">для Поставщика. </span>
                                <span className="whitespace-nowrap">При этом</span> <span className="whitespace-nowrap">у самого</span> Поставщика появляется возможность пополнения оборотных средств за счет своевременного сокращения кассовых разрывов. Этому способствует предлагаемая система документооборота, помогающая отслеживать поставки и оплату.
                            </div>
                        </div>
                        <div className="flex flex-col items-start">
                            <div className="w-2/3 lg:w-full aspect-[1.66] rounded-2xl bg-slate-200 overflow-hidden pointer-events-none">
                                <img className="w-full h-full object-cover" src="./person1.jpg" alt="" />
                            </div>
                            <div className="flex flex-col">
                                <div className="mt-6 text-xl font-medium">
                                    Алия Айжулова
                                </div>
                                <div className="text-xl text-black/40">
                                    CEO
                                </div>
                                <div className="text-base mt-6">
                                    20 лет работы <span className="whitespace-nowrap">в банковском</span> секторе. Руководила подразделениями Бизнес-развития, МСБ, Службы Безопасности в банках ForteBank, Kaspi Bank, Банк ЦентрКредит, подразделением Крупных корпораций (UniCredit Kazakhstan). <br />
                                    Обладает профессиональными навыками фин. и инвест. анализа, автоматизации бизнес-процессов, Стандарты управления проектами (PMI), имеет опыт разработки и внедрения ERP, интегрировала банковские системы <span className="whitespace-nowrap">с государственными</span> источниками данных. <br />
                                    Вице-президент Казахстанской Ассоциации франчайзинга, приглашенный лектор Бизнес школы. <br />
                                    Активист <span className="whitespace-nowrap">по поддержке</span> развития МСБ <span className="whitespace-nowrap">в Казахстане.</span>
                                </div>
                            </div>
                        </div>

                        {/* <div className="flex flex-col items-start">
                            <div className="w-2/3 lg:w-full aspect-[1.66] rounded-2xl bg-slate-200 overflow-hidden pointer-events-none">
                                <img className="w-full h-full object-cover" src="./person1.jpg" alt="" />
                            </div>
                            <div className="flex flex-col">
                            <div className="mt-6 text-xl font-medium">
                                Алия Айжулова
                            </div>
                            <div className="text-xl text-black/40">
                                CEO
                            </div>
                            <div className="text-base mt-6">
                                20 лет работы в банковском секторе. Руководила подразделениями Бизнес-развития, в МСБ, СБ СenterCredit, ForteBank, Kaspi Bank, Крупных корпораций (UniCredit Kazakhstan). Обладает профессиональными навыками фин. и инвест.  анализа, автоматизации бизнес-процессов, Стандарты управления проектами (PMI), имеет опыт разработки и внедрения ERP, интегрировала банковские системы с государственными источниками данных. Вице-президент Ассоциации франчайзинга, приглашенный лектор Бизнес школы. Активист по поддержке развития МСБ в Казахстане.
                            </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>

            <div className="w-auto max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-16">
                <div className="bg-[#f9d370] rounded-2xl flex flex-col sm:flex-row sm:justify-between sm:items-center p-8">
                    <div>
                        <div className="text-xl sm:text-2xl font-medium">Оставьте заявку прямо сейчас</div>
                        <div>Мы с вами свяжемся в ближайшее время</div>
                    </div>
                    <button onClick={() => window.open("/app/registration", "_self")} className="py-3 px-6 mt-4 sm:mt-0 bg-[#12436E] text-[#f9d370] rounded-md font-medium hover:bg-[#12436E]/80 duration-300">
                        Добавить компанию
                    </button>
                </div>
            </div>

            {/* <div>
                <div className="w-auto max-w-7xl mx-auto mt-8 pb-8 px-4 sm:px-6 lg:px-8">
                    <p className="text-black/40 mt-1">
                        В Республике Казахстан факторинг регулируется ст.729 ГК РК
                    </p>
                </div>
            </div> */}



            <div className="bg-[#12436E] pt-16 pb-6">
                <div className="flex flex-col sm:flex-row w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 justify-between">
                    <div className="flex flex-row items-center sm:w-1/4">
                        <div className="h-12 w-12">
                            <img className="w-12 h-12" src="./logo2.svg" alt="" />
                        </div>
                        <div className="flex flex-col ml-4">
                            <div className="text-white text-xl">e-Lab Factor</div>
                            <div className="text-sm text-white/75">Факторинговая компания</div>
                            {/* <div className="text-sm text-white">
                                это новый формат предоставления услуги факторинга, созданный для решения задач Поставщиков и Покупателей, и направленный на оптимизацию управления оборотными средствами компании и администрирование дебиторской задолженности
                            </div> */}
                        </div>
                    </div>
                    <div>
                        <div className="flex flex-col gap-1 mt-8 sm:mt-0">
                            <a href="mailto:admin@elabfactor.com" className="text-white opacity-60 duration-300 hover:opacity-90">admin@elabfactor.com</a>
                            <a href="tel:+77017887238" className="text-white opacity-60 duration-300 hover:opacity-90">+7 (701) 788 72-38</a>
                        </div>
                    </div>
                    {/* <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 sm:w-3/4 gap-8 mt-8 sm:mt-0"> */}
                    {/* <div className="flex flex-col text-white gap-2">
                            <div className="font-medium pb-2">
                                Решения
                            </div>
                            <div className="opacity-60">
                                Электронная система документооборота
                            </div>
                        </div> */}
                    {/* <div className="flex flex-col text-white gap-2">
                            <div className="font-medium pb-2">
                                Компания
                            </div>
                            <div className="opacity-60">
                                О нас
                            </div>
                            <div className="opacity-60">
                                Карьера
                            </div>
                            <div className="opacity-60">
                                Партнерам
                            </div>
                        </div> */}
                    {/* <div className="flex flex-col text-white gap-2">
                            <div className="font-medium pb-2">
                                Компания
                            </div>
                            <div className="opacity-60">
                                О нас
                            </div>
                            <div className="opacity-60">
                                Карьера
                            </div>
                            <div className="opacity-60">
                                Партнерам
                            </div>
                        </div> */}
                    {/* <div className="flex flex-col text-white gap-2">
                            <div className="font-medium pb-2">
                                Компания
                            </div>
                            <div className="opacity-60">
                                О нас
                            </div>
                            <div className="opacity-60">
                                Карьера
                            </div>
                            <div className="opacity-60">
                                Партнерам
                            </div>
                        </div> */}
                    {/* </div> */}
                </div>
                <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-4 border-t border-white/20 mt-24">
                    <div className="text-sm text-white/40">
                        © 2023 e-Lab Factor. All rights reserved.
                    </div>
                </div>
            </div>
            <FeedbackModal active={isFormOpened} setOpen={setFormOpen}></FeedbackModal>
        </div>
    )
}

const FeedbackModal: FC<{ active: boolean, setOpen: any }> = ({ active, setOpen }) => {
    const name = useRef<any>()
    const email = useRef<any>()
    const phone = useRef<any>()
    const message = useRef<any>()

    const handleSendForm = async () => {
        if (name.current.value.replace(" ", "").length > 1
            && (phone.current.value.replace(" ", "").replace("+", "").replace("(", "").replace(")", "").length > 10 || validateEmail(email.current.value)) && message.current.value.length > 5) {
            const response = await sendForm({ "email": email.current.value || "", "name": name.current.value, "phone": phone.current.value || "", "comment": message.current.value })
            console.log(response)
            setOpen(false)
        }
    }

    if (!active)
        return <></>

    return (
        <div className="bg-black/40 flex flex-row justify-center items-start pt-8 sm:pt-16 fixed top-0 left-0 w-screen h-screen">
            <div className="w-full mx-auto max-w-xl bg-white rounded-xl p-4">
                <div className="flex flex-row justify-between">
                    <div className="text-2xl font-medium">Обратиться с вопросом</div>
                    <button onClick={() => { setOpen(false) }}>
                        <XIcon className="w-6 h-6"></XIcon>
                    </button>
                </div>

                <div className="mt-1 text-black/60">Вы можете задать свой вопрос по форме ниже</div>
                <div className="mt-4">
                    <label className="block font-medium leading-6 text-gray-900 mt-2">Имя</label>
                    <input ref={name} type="text" className="w-full mt-2 bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                </div>
                <div className="">
                    <label className="block font-medium leading-6 text-gray-900 mt-2">Email</label>
                    <input ref={email} type="text" className="w-full mt-2 bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                </div>
                <div className="">
                    <label className="block font-medium leading-6 text-gray-900 mt-2">Телефон</label>
                    <input ref={phone} type="text" className="w-full mt-2 bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                </div>
                <div className="">
                    <label className="block font-medium leading-6 text-gray-900 mt-2">Сообщение</label>
                    <textarea ref={message} rows={3} className="w-full mt-2 bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                </div>
                <div className="flex flex-row justify-end mt-6">
                    <button onClick={handleSendForm} className="py-2 px-8 text-lg text-white bg-[#12436E] rounded-md">Отправить</button>
                </div>
            </div>
        </div>
    )
}

export default HomePage