import axios from 'axios';
const SERVER_URL = "https://elabfactor.com";

const getContracts = async (value: string) => {
    const ENDPOINT = `${SERVER_URL}/api/contracts/getContractsByClientId.php?id=${value}`;

    try {
        let response = await axios.get(ENDPOINT, {
            headers: {
                'content-type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },
        })

        return response.data
    } catch (e: any) {
        console.log(e.response!.data.message);
        if (e.response.data.message == "Access denied.")
            window.open("/app/auth", "_self")
    }
}

const addContract = async (contractFiles: any, client_id: string, contract_name: string, signing_date: string, cost: number, ending_date: string) => {
    const ENDPOINT = `${SERVER_URL}/api/contracts/addContract.php`

    const formData = new FormData();
    for (var i = 0; i < contractFiles.length; i++) {
        let file = contractFiles[i];
        formData.append('contractFiles[' + i + ']', file);
    }
    formData.append('client_id', client_id.toString());
    formData.append('contract_name', contract_name.toString());
    formData.append('signing_date', signing_date.toString());
    formData.append('cost', cost.toString());
    formData.append('ending_date', ending_date.toString());

    try {
        let response = await axios.post(ENDPOINT, formData, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },
        });

        return response.data;
    } catch (e: any) {
        console.log(e.response!.data.message);
        if (e.response.data.message == "Access denied.")
            window.open("/app/auth", "_self")
    }
}

export { getContracts, addContract }