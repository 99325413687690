import { FC, useState, useEffect } from "react";
import { ArrowNarrowRightIcon, ArrowNarrowLeftIcon, PlusSmIcon, TrashIcon } from "@heroicons/react/outline";
import AppHeader from "../../components/appHeader/AppHeader";
import AddClient from "./components/addClient/AddClient";
import { getClientsWithPage, getClientsCount } from "../../libs/clientController";
import { checkAndOut } from "../../libs/authContoller";
import ViewClient from "./components/viewClient/ViewClient";

const Client: FC<{ id: number, name: string, bin: string, viewClient: any }> = ({ id, name, bin, viewClient }) => {
    return (
        <div onClick={() => { viewClient({ state: true, id: id }) }} className="overflow-hidden group relative bg-white border-t flex flex-col justify-between py-4 px-4 duration-300 hover:bg-slate-50 cursor-pointer">
            <div className="flex flex-row">
                {/* <div className="h-16 aspect-1 bg-gray-400"></div> */}
                <div className="grid grid-cols-2 w-full items-center">
                    <div className="font-medium ">{name}</div>
                    <div className="text-black/70 flex flex-row justify-between items-center">
                        <div>
                            {bin.replace(/(\d{3})(\d{3})(\d{3})(\d{3})/, "$1-$2-$3-$4")}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const MyClients = () => {
    const [clients, setClients] = useState([]);
    const [searchValue, setSearchValue] = useState("")
    const [searchFinalValue, setSearchFinalValue] = useState("")
    const [pagesCount, setPagesCount] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [isOpenAddClientModal, setOpenAddClientModal] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const [viewClient, setViewClient] = useState({ state: false, id: 0 })
    document.title = "Мои контрагенты"

    document.body.style.overflow = isOpenAddClientModal || viewClient.state ? 'hidden' : 'auto'

    const updateSearchValue = async (e: any) => {
        setSearchValue(e.target.value)
        const temp = e.target.value
        setTimeout(function () {
            if (temp == e.target.value)
                setSearchFinalValue(e.target.value)
        }, 1000);
    }

    useEffect(() => {
        checkAndOut()
    }, [])

    const renderConsumers = async () => {
        setLoading(true)
        setClients([])
        const temp_clients: any = [];
        const response: any = await getClientsWithPage(searchFinalValue, currentPage - 1);
        response.map((element: any) => {
            temp_clients.push(<Client viewClient={setViewClient} key={element.client_name} name={element.client_name} bin={element.client_bin} id={element.client_id}></Client>)
        });
        setClients(temp_clients);
        setLoading(false)
    }

    const renderCount = async () => {
        const response: any = await getClientsCount(searchValue)
        setPagesCount(Math.ceil(response.data.count / 10))
    }

    useEffect(() => {
        renderConsumers();
        renderCount()
    }, [searchFinalValue])

    useEffect(() => {
        setLoading(true)
        if (!isLoading)
            renderConsumers()
    }, [currentPage])

    return (
        <div className="pb-16 relative min-h-screen bg-slate-200">
            <div className="absolute top-0 left-0 w-full h-80 bg-gradient-to-br from-[#355a79] to-[#1C4B74]">

            </div>
            <header className="z-40 relative">
                <AppHeader></AppHeader>
            </header>
            <main className="pb-16 relative z-10">
                <div className=" max-w-7xl w-full mx-auto gap-x-4 mt-8 sm:mt-8 px-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col sm:flex-row sm:justify-between items-start w-full gap-4">
                        <div className="flex flex-col">
                            <div className="text-4xl font-medium text-white">
                                Мои контрагенты
                            </div>
                            <div className="w-full sm:w-auto">
                                <input value={searchValue} onChange={e => updateSearchValue(e)} type="text" placeholder="Поиск" className="duration-300 transition-colors placeholder:text-white/40 focus:placeholder:text-white/80 w-full sm:w-auto mt-4 border border-white/20 bg-transparent text-white rounded-full py-2 px-4 focus:border-white/50 focus:ring-0 sm:leading-6" />
                            </div>
                        </div>
                        <div>
                            <div onClick={() => setOpenAddClientModal(true)} className="items-center flex flex-row bg-[#f9d370] text-[#12436E] rounded-full text-base font-medium pl-4 pr-6 py-2 hover:bg-[#D3AE50] duration-300 cursor-pointer">
                                <PlusSmIcon className="h-6 mr-2"></PlusSmIcon>
                                Добавить
                            </div>
                        </div>
                    </div>
                    <div className="col-span-3 w-full bg-white rounded-lg overflow-hidden mt-8">
                        <div className="flex flex-row bg-slate-100">
                            <div className="grid grid-cols-2 w-full py-4 px-4">
                                <div className="font-medium ">Имя</div>
                                <div className="font-medium ">BIN</div>
                            </div>
                        </div>

                        {
                            (!isLoading && clients.length == 0) ?
                                <div className="flex flex-col justify-center items-center">
                                    <div className="text-center py-32 text-xl px-16 sm:px-0">
                                        По вашему запросу ничего не найдено
                                    </div>
                                </div> :
                                clients.length == 0 ? Array.from(Array(10).keys()).map((element) => {
                                    return (
                                        <div key={element} className="overflow-hidden relative bg-white border-t flex flex-col justify-between py-4 px-4">
                                            <div className="flex flex-row">
                                                {/* <div className="h-16 aspect-1 bg-gray-400"></div> */}
                                                <div className="font-medium bg-gray-100 text-gray-100 rounded-md w-full h-6"></div>
                                            </div>
                                        </div>)
                                }) : clients.map((elem) => elem)}

                    </div>
                </div>

                {
                    pagesCount != 1 ?
                        <div className="max-w-7xl w-full mx-auto flex flex-col-reverse sm:flex-row gap-x-4 mt-8 sm:mt-16 px-4 sm:px-6 lg:px-8">
                            <nav className="w-full border-t border-gray-200 flex items-center justify-between sm:px-0">
                                <div className="-mt-px w-0 flex-1 flex">
                                    {
                                        currentPage != 1 ? <a onClick={() => { setCurrentPage(page => { return page - 1 }) }} className="cursor-pointer px-4 inline-flex items-center text-sm font-medium duration-300 text-gray-500 hover:text-gray-700 py-2 hover:bg-slate-50/50 rounded-full" >
                                            <ArrowNarrowLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            Предыдущая
                                        </a> : <></>
                                    }

                                </div>
                                <div className="flex md:-mt-px gap-1 sm:gap-2">
                                    {
                                        Array.from(Array(3).keys()).reverse().map((element) => {
                                            if (currentPage - 1 - element > 0)
                                                return (
                                                    <a key={`Page ${currentPage - 1 - element}`} onClick={() => setCurrentPage(currentPage - 1 - element)} className="hidden sm:inline-flex text-gray-500 rounded-full cursor-pointer duration-300 hover:text-gray-700 hover:bg-slate-50/50 px-4 aspect-1 items-center text-sm font-medium" aria-current="page">
                                                        {currentPage - 1 - element}
                                                    </a>
                                                )
                                        })
                                    }
                                    <a key={`Page ${currentPage}`} className="text-black rounded-full bg-slate-50 px-4 aspect-1 inline-flex items-center text-sm font-medium" aria-current="page">
                                        {currentPage}
                                    </a>
                                    {
                                        Array.from(Array(3).keys()).map((element) => {
                                            if (currentPage + 1 + element <= pagesCount)
                                                return (
                                                    <a key={`Page ${currentPage + 1 + element}`} onClick={() => setCurrentPage(currentPage + 1 + element)} className="hidden sm:inline-flex text-gray-500 rounded-full cursor-pointer duration-300 hover:text-gray-700 hover:bg-slate-50/50 px-4 aspect-1 items-center text-sm font-medium" aria-current="page">
                                                        {currentPage + 1 + element}
                                                    </a>
                                                )
                                        })
                                    }
                                </div>
                                <div className="-mt-px w-0 flex-1 flex justify-end">
                                    {
                                        currentPage < pagesCount ?
                                            <a onClick={() => { setCurrentPage(page => { return page + 1 }) }} className="cursor-pointer px-4 inline-flex items-center text-sm font-medium duration-300 text-gray-500 hover:text-gray-700 py-2 hover:bg-slate-50/50 rounded-full" >
                                                Следующая
                                                <ArrowNarrowRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </a> : <></>
                                    }
                                </div>
                            </nav>
                        </div> : <></>
                }

            </main>
            <AddClient active={isOpenAddClientModal} setOpen={setOpenAddClientModal} renderFunction={renderConsumers}></AddClient>
            <ViewClient viewRef={viewClient} setViewRef={setViewClient}></ViewClient>
        </div>
    )
}

export default MyClients;