import { FC, useRef, useState, useEffect, useContext } from "react"
import { addDelivery } from "../../../../libs/deliveriesController";
import { DateToShortTimestamp, ShortTimestampToString } from "../../../../utils/dates";
import { getAllClients } from "../../../../libs/clientController";
import FormHook from "../../../../hooks/InputForm";
import ReactLoading from 'react-loading';
import DocumentInLine from "../../../../components/forms/DocumentInLine";
import ModalDatapicker from "../../../../components/forms/ModalDatapicker";
import ModalMultipleDatepicker from "../../../../components/forms/ModalMultipleDatapicker";
import ContractSelection from "../../../../components/modals/ContractSelection";
import MultipleDocumentInLine from "../../../../components/forms/MultipleDocumentInLine";
import { DeliveryContext } from "../../../../context/DeliveryContext";

const AddDelivery: FC<{ active: boolean, setOpen: any, renderFunction: any }> = ({ active, setOpen, renderFunction }) => {
    const deliveryContext = useContext(DeliveryContext)
    const existingData: any = deliveryContext.data

    const [name, setName] = useState(existingData.name || "");
    const [bin, setBIN] = useState(existingData.bin || "")
    const [clientId, setClientId] = useState(existingData.client_id || "")
    const [paymentDates, setPaymentDates] = useState<Array<any>>(existingData.payment_dates || [DateToShortTimestamp(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())])
    const [deliveryDate, setDeliveryDate] = useState(existingData.delivery_date || DateToShortTimestamp(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))
    const [deliveryCost, setDeliveryCost] = useState(existingData.delivery_cost || 0)
    const [contract, setContract] = useState<any>(existingData.contract || null)
    const [isOpenSelectionClient, setOpenSelectionClient] = useState(false)
    const [isDatepickerOpen, setOpenDatepicker] = useState(false)
    const [isMultipleDatepickerOpen, setOpenMultipleDatepicker] = useState(false)
    const [isModalWarmingOpened, setModalWarmingOpen] = useState(false)
    const [foundedClients, setFoundedCleints] = useState<any>([{ "id": 1, "name": "ПАО Ромашка", "BIN": "1283467468" }]);

    const [uploadingFiles, setUploadingFiles] = useState(false)
    const [selectionDisabled, setSelectionDisabled] = useState(bin ? false : true)
    const [selectionOpened, setSelectionOpened] = useState(false)
    const [comment, setComment] = useState(existingData.comment || "")

    const [invoiceFiles, setInvoiceFiles] = useState<any>(existingData.invoice_files || []);
    const [factorFiles, setFactorFiles] = useState<any>(existingData.factor_files || []);
    const [acceptingFiles, setAcceptingFiles] = useState<any>(existingData.act_files || []);
    const [othersFiles, setOthersFiles] = useState<any>(existingData.others_files || []);

    const [hasErrorContract, setErrorContract] = useState(false)

    const forms = {
        name: FormHook((e: string) => { return e.length > 0 }),
        cost: FormHook((e: string) => { return Number(e.split(" ").join("")) > 0 }),
    }

    useEffect(() => {
        if (active) {
            setClientId(existingData.client_id)
            setPaymentDates(existingData.payment_dates)
            setDeliveryCost(existingData.delivery_cost)
            setDeliveryDate(existingData.delivery_date)
            setInvoiceFiles(existingData.invoice_files)
            setFactorFiles(existingData.factor_files)
            setAcceptingFiles(existingData.act_files)
            setOthersFiles(existingData.others_files)
            setName(existingData.name)
            setBIN(existingData.bin)
            setContract(existingData.contract)
            setComment(existingData.comment)
        }
    }, [active])

    useEffect(() => {
        setErrorContract(false)
    }, [contract])

    const handleSendButton = async () => {
        let check = forms.name.validationFunction()
        check &= forms.cost.validationFunction()

        if (!contract)
            setErrorContract(true)

        if (!check || !contract.contract_id)
            return

        console.log('Sent')
        setUploadingFiles(true)
        const response = await addDelivery(contract.contract_id, invoiceFiles, factorFiles, acceptingFiles, othersFiles, clientId, paymentDates, deliveryDate, deliveryCost, comment)
        setUploadingFiles(false)
        setModalWarmingOpen(false)
        setOpen(false)
        deliveryContext.setDelivery({
            name: "",
            bin: "",
            client_id: "",
            invoice_files: [],
            factor_files: [],
            act_files: [],
            others_files: [],
            payment_dates: [DateToShortTimestamp(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())],
            delivery_date: DateToShortTimestamp(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            delivery_cost: 0,
            contract: {},
            comment: ""
        })
        renderFunction()
    }

    const selectClient = (id: number, name: string, bin: string) => {
        setName(name)
        setErrorContract(false)
        forms.name.onchange()
        setTimeout(() => { setBIN(bin); setSelectionDisabled(false); setClientId(id.toString()) }, 50)
    }

    const renderFoundedClients = async () => {
        const response = await getAllClients(name)
        setFoundedCleints(response)
    }

    useEffect(() => {
        renderFoundedClients()
    }, [name])

    const handleCancel = () => {
        setModalWarmingOpen(false)
        deliveryContext.setDelivery({
            name: name,
            bin: bin,
            client_id: clientId,
            invoice_files: invoiceFiles,
            factor_files: factorFiles,
            act_files: acceptingFiles,
            others_files: othersFiles,
            payment_dates: paymentDates,
            delivery_date: deliveryDate,
            delivery_cost: deliveryCost,
            contract: contract,
            comment: comment
        })
        setOpen(false)
    }

    const formatNumber = (value: string) => {
        return value.split("").reverse().join("").replace(/(.{3})/g, "$1 ").split("").reverse().join("").trim()
    }

    const handleOpenContracts = () => {
        setSelectionOpened(true)
    }

    if (!active)
        return <></>

    return (
        <div className="bg-black/40 w-screen h-screen fixed top-0 left-0 flex flex-col justify-start sm:items-center pt-8 sm:pt-16 z-40 overflow-y-auto">
            <div className='w-full sm:max-w-3xl px-4 sm:px-6 lg:px-8 pb-8'>
                <div className="bg-white p-4 rounded-xl flex flex-col sm:grid sm:grid-cols-4 gap-2 overflow-hidden relative">
                    <div className="text-2xl font-medium col-span-4">Добавление поставки</div>
                    {/* <div className="mt-2 text-black/60 col-span-4">Данный договор будет отправлено оппоненту для подтверждения</div> */}
                    <div className="mt-4 col-span-4 relative">
                        <label className="block font-medium leading-6 text-gray-900 mt-4 relative">Наименование покупателя</label>
                        <input onInput={() => {
                            setBIN("")
                            setClientId("")
                            setSelectionDisabled(true)
                            setContract(null)
                        }}
                            ref={forms.name.ref}
                            onBlur={() => setTimeout(() => setOpenSelectionClient(false), 100)}
                            onFocus={() => { setOpenSelectionClient(true) }}
                            value={name}
                            onChange={e => { setName(e.target.value); forms.name.onchange() }}
                            type="text"
                            className="relative w-full mt-2 bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6"
                        />
                        {isOpenSelectionClient && foundedClients.length != 0 ?
                            <div className="absolute bg-white w-full shadow flex flex-col divide-y px-2 py-2 rounded-lg text-sm">
                                {
                                    foundedClients.slice(0, 5).map((element: any) => {
                                        return (
                                            <div onClick={() => selectClient(element.client_id, element.client_name, element.client_bin)} className="flex flex-row justify-between px-4 py-2 hover:bg-gray-50 cursor-pointer rounded">
                                                <div>{element.client_name}</div>
                                                <div>{element.client_bin.replace(/(\d{3})(\d{3})(\d{3})(\d{3})/, "$1-$2-$3-$4")}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div> : <></>
                        }
                    </div>
                    <div className="col-span-2">
                        <label className="block font-medium leading-6 text-gray-900 mt-2">БИН</label>
                        <input disabled value={bin.replace(/(\d{3})(\d{3})(\d{3})(\d{3})/, "$1-$2-$3-$4")} type="text" className="w-full mt-2 bg-gray-100 block flex-1 border-1 border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                    </div>
                    <div className="col-span-2"></div>
                    <div className="col-span-1">
                        <label className="block font-medium leading-6 text-gray-900 mt-2">Дата поставки</label>
                        <div onClick={() => setOpenDatepicker(true)} className="h-9 w-full mt-2 bg-gray-100 block flex-1 border-0 rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6">
                            <div className="text-sm">
                                {
                                    ShortTimestampToString(deliveryDate)
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-span-3"></div>
                    <div className="col-span-2">
                        <label className="block font-medium leading-6 text-gray-900 mt-2">Даты оплаты</label>
                        <div onClick={() => setOpenMultipleDatepicker(true)} className="flex flex-row flex-wrap gap-1 w-full mt-2 bg-gray-100 flex-1 border-0 rounded py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6">
                            {
                                paymentDates.length != 0 ?
                                    paymentDates.sort().map((elem) => {
                                        return (
                                            <div className="py-1 px-2 text-sm bg-slate-200 rounded">
                                                {ShortTimestampToString(elem)}
                                            </div>
                                        )
                                    })
                                    : <div className="h-6"></div>
                            }
                        </div>
                    </div>
                    <div className="col-span-2">
                        <label className="block font-medium leading-6 text-gray-900 mt-2">Сумма оплаты</label>
                        <input ref={forms.cost.ref} value={formatNumber(deliveryCost.toString())} onChange={e => { setDeliveryCost(isNaN(Number(e.target.value.split(" ").join(""))) ? Number(e.target.value.slice(0, -1).split(" ").join("")) : Number(e.target.value.split(" ").join(""))); forms.cost.onchange() }} type="text" className="w-full mt-2 bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                        {/* {
                            paymentDates.length > 1 ?
                                <div className="text-sm text-black/60 mt-1">
                                    Размер одного платежа: {(deliveryCost / paymentDates.length).toFixed(2)}
                                </div>
                                : <></>
                        } */}
                    </div>

                    <div className="mt-6 text-xl border-t pt-4 col-span-4">
                        <div className="">
                            <div className="flex flex-row text-base items-center justify-start">
                                <div className="font-medium">Договор</div>
                                <button onClick={handleOpenContracts} disabled={selectionDisabled} className={`ml-2 ${hasErrorContract ? "text-red-600 " : " text-blue-600 "} disabled:text-gray-500`}>Выбрать</button>
                            </div>
                            {
                                contract?.contract_id ? <div className="text-base mt-4">
                                    <div className="bg-white py-1 flex flex-row gap-8 text-base text-left relative duration-300 cursor-pointer">
                                        <div className="flex flex-col">
                                            <div className="font-medium pb-1">Наименование</div>
                                            <div>{contract.contract_name}</div>
                                        </div>
                                        <div className="flex flex-col hidden lg:block">
                                            <div className="font-medium pb-1 ">Дата заключения</div>
                                            <div>{ShortTimestampToString(contract.signing_date)}</div>
                                        </div>
                                        <div className="flex flex-col">
                                            <div className="font-medium pb-1">Сумма</div>
                                            <div>{contract.cost}</div>
                                        </div>
                                        <div className="flex flex-col hidden lg:block">
                                            <div className="font-medium pb-1">Срок окончания</div>
                                            <div>{ShortTimestampToString(contract.ending_date)}</div>
                                        </div>

                                    </div>
                                </div>
                                    : <></>
                            }
                        </div>
                    </div>

                    <div className="mt-2 text-xl border-t pt-4 col-span-4">
                        {/* <label className="block font-medium leading-6 text-gray-900 text-xl border-t pt-4">Документ</label> */}
                        <div className="grid grid-cols-1 gap-3 grid grid-cols-2">
                            <MultipleDocumentInLine key={"Форма Накладная"} name="Накладная" doc_info="не помню" files={invoiceFiles} setFiles={setInvoiceFiles}></MultipleDocumentInLine>
                            <MultipleDocumentInLine key={"Форма Счёт Фактура"} name="Счёт Фактура" doc_info="не помню" files={factorFiles} setFiles={setFactorFiles}></MultipleDocumentInLine>
                            <MultipleDocumentInLine key={"Форма Акт ПП/ВР"} name="Акт ПП/ВР" doc_info="не помню" files={acceptingFiles} setFiles={setAcceptingFiles}></MultipleDocumentInLine>
                            <MultipleDocumentInLine key={"Форма Прочие файлы"} name="Прочие файлы" doc_info="не помню" files={othersFiles} setFiles={setOthersFiles}></MultipleDocumentInLine></div>
                    </div>
                    <div className="col-span-4 border-t mt-4 pt-4">
                        <label className="block font-medium leading-6 text-gray-900 mt-2">Комментарий</label>
                        <textarea value={comment} onChange={e => setComment(e.target.value)} className="w-full mt-2 bg-gray-100 block flex-1 border-0 rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" rows={3}></textarea>
                    </div>
                    <div className="flex flex-row mt-10 gap-x-4 col-span-4">
                        <button disabled={uploadingFiles} onClick={handleCancel} className="py-2 bg-gray-200 hover:bg-slate-100 duration-300 w-2/5 font-medium rounded">Отмена</button>
                        <button disabled={uploadingFiles} onClick={handleSendButton} className="py-2 bg-[#006C93] hover:opacity-80 duration-300 text-white font-medium w-3/5 rounded">Отправить</button>
                    </div>
                    {
                        uploadingFiles ?
                            <div className="absolute w-full h-full z-[99] flex flex-col top-0 left-0 justify-center items-center bg-black/20">
                                <div className="w-16 h-16">
                                    <ReactLoading type="spin" color="#fff"></ReactLoading>
                                </div>
                            </div>
                            : <></>
                    }
                </div>
            </div>
            <ModalMultipleDatepicker active={isMultipleDatepickerOpen} setOpen={setOpenMultipleDatepicker} selectedDates={paymentDates} setSelectedDates={setPaymentDates}></ModalMultipleDatepicker>
            <ModalDatapicker active={isDatepickerOpen} setOpen={setOpenDatepicker} selectedDate={deliveryDate} setDate={setDeliveryDate}></ModalDatapicker>
            <ContractSelection active={selectionOpened} setOpen={setSelectionOpened} setContractId={setContract} client_id={clientId}></ContractSelection>
            <WamingModal active={isModalWarmingOpened} setOpen={setModalWarmingOpen} cancelButton={handleCancel}></WamingModal>
        </div>
    )
}

const WamingModal: FC<{ cancelButton: any, setOpen: any, active: boolean }> = ({ cancelButton, setOpen, active }) => {

    if (!active)
        return <></>

    return (
        <div className="bg-black/40 fixed w-screen h-screen top-0 left-0 flex flex-row justify-center items-center">
            <div className="bg-white rounded-xl max-w-2xl p-4">
                <div className="text-xl font-medium mt-1">Вы точно хотите отменить добавление поставки?</div>
                <div>Все введенный данные будут сброшены.</div>
                <div className="flex flex-row pt-8 gap-2 justify-end">
                    <button onClick={cancelButton} className="py-2 px-4 bg-red-600 rounded-md text-white text-lg">Отменить</button>
                    <button onClick={() => setOpen(false)} className="py-2 px-4 bg-slate-200 rounded-md text-lg">Остаться</button>
                </div>
            </div>
        </div>
    )
}

export default AddDelivery;