import { FC, useRef, useState } from "react";
import { uploadFile, removeFile } from "../../libs/uploadFile";
import ReactLoading from "react-loading";
import { DownloadIcon } from "@heroicons/react/outline";

const DocumentBlockWithBorders: FC<{ doc_info: any, name: string, tamplate_url: string }> = ({ doc_info, name, tamplate_url }) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [loading, setLoading] = useState(false)

    const handleUploadClick = () => {
        inputRef.current?.click();
    }

    const handleUploadFile = async (e: any) => {
        // console.log(e.target.parentNode)
        setLoading(true)
        const response = await uploadFile(e.target.files[0], name)
        doc_info["isLoaded"][1](true);
        doc_info["location"][1](response.data.location)
        setLoading(false)
    }

    const handleRemoveFile = async () => {
        setLoading(true)
        await removeFile({ "location": doc_info["location"][0] })
        doc_info["isLoaded"][1](false);
        doc_info["location"][1]("")
        setLoading(false)
    }

    return (
        <>
            {
                !doc_info["isLoaded"][0] ?
                    <>
                        <div id={name} className="bg-transparent border-2 border-[#12436E]/60 border-dashed rounded-lg flex flex-col items-start p-4">
                            <div className="flex flex-row items-center">
                                <p className="text-black font-medium text-xl">{name}</p>
                                {
                                    tamplate_url != "" ?
                                        <DownloadIcon onClick={() => window.open(tamplate_url)} className="h-5 w-5 ml-2 text-black/60 cursor-pointer"></DownloadIcon>
                                        : <></>
                                }
                            </div>

                            <button onClick={handleUploadClick} className="text-[#02718D]  mt-2">Добавить</button>
                            <input type="file" accept="application/pdf" ref={inputRef} className="hidden absolute" onChange={e => handleUploadFile(e)} />
                        </div>

                    </>
                    :
                    <div className="bg-white border rounded-lg p-4">
                        <p className="text-black font-medium text-xl">{name}</p>
                        <div className="mt-2 flex flex-row gap-x-4">
                            <a href={`https://elabfactor.com/api/files/downloadFile.php?token=${localStorage.getItem('access_token')}&file=${encodeURIComponent(doc_info["location"][0])}`} className="text-blue-600">Скачать</a>
                            <a onClick={handleRemoveFile} className="text-red-600 hover:cursor-pointer">Удалить</a>
                        </div>
                    </div>
            }
            {
                loading ? <div className="bg-black/20 fixed z-[100] flex flex-col top-0 left-0 justify-center items-center w-screen h-screen">
                    <div className="w-16 h-16">
                        <ReactLoading type="spin" color="#fff"></ReactLoading>
                    </div>
                </div> : <></>
            }
        </>
    )
}

export default DocumentBlockWithBorders