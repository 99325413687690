import { useEffect, useState } from "react";
import AppHeader from "../../components/appHeader/AppHeader"
import { getInfoProfile } from "../../libs/profileController";
import { getDocuments } from "../../libs/uploadFile";
import phoneFormat from "../../utils/phoneFormater";
import DocumentBlockWithBorders from "../../components/forms/DocumentBlockWithBorders";
import ChangingEmail from "../../components/modals/ChangeEmail";
import ChangePassword from "../../components/modals/ChangePassword";
import { logout, checkAndOut } from "../../libs/authContoller";
import { LogoutIcon } from "@heroicons/react/outline";

const Profile = () => {
    const [closeWarming, setCloseWarming] = useState(false);
    const [changeEmailModal, setChangeEmailModal] = useState(false);
    const [changePasswordModal, setChangePasswordModal] = useState(false);

    document.body.style.overflow = changeEmailModal || changePasswordModal ? 'hidden' : 'auto'

    // Profile Info
    const [profile, setProfile] = useState({
        comp_name: "",
        comp_bin: "",
        manager_fullname: "",
        comp_address: "",
        email: "",
        contact_position: "",
        contact_fullname: "",
        contact_phone: "",
        contact_email: ""
    })
    const [isLoading, setLoading] = useState(true)

    // Modal
    // const [isOpenModal, setOpenModal] = useState(false)
    // const [modalValue, setModalValue] = useState("")
    // const [modalTitle, setModalTitle] = useState("")
    // const [modalDesc, setModalDesc] = useState("")
    // const [modalDanger, setModalDanger] = useState(false)

    useEffect(() => {
        checkAndOut()
    }, [])

    const renderInfo = async () => {
        setLoading(true)
        const response = await getInfoProfile()
        setProfile(response)
        const documentsResponse: any = await getDocuments()
        documentsResponse.data.map((element: any) => {
            docs[element.doc_name].isLoaded[1](true)
            docs[element.doc_name].location[1](element.location)
        })
        setLoading(false)
    }

    useEffect(() => {
        renderInfo()
    }, [])

    document.title = "Личный кабинет"

    const docs: any = {
        "Реестр поставок": { "isLoaded": useState(false), "location": useState("") },
        "Заявка": { "isLoaded": useState(false), "location": useState("") },
        "Анкета": { "isLoaded": useState(false), "location": useState("") }
    }

    return (
        <div className="pb-4 sm:pb-8 lg:pb-8 bg-slate-200 min-h-screen">
            <div className="absolute top-0 left-0 w-full h-80 bg-gradient-to-br from-[#355a79] to-[#1C4B74]">
            </div>
            <header className="relative z-30">
                <AppHeader></AppHeader>
            </header>
            <main className="relative z-20">
                <div className="mx-auto w-full max-w-7xl mt-8 sm:mt-8 overflow-hidden relative px-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col sm:flex-row sm:justify-between items-start w-full gap-4">
                        <div className="flex flex-col">
                            <div className="text-4xl font-medium text-white">
                                Личный кабинет
                            </div>

                        </div>
                        <div>
                        </div>
                    </div>
                    <div className="w-full flex flex-col sm:grid grid-cols-1 sm:grid-cols-6 mt-8 gap-y-6 gap-x-4 bg-white px-8 py-6 rounded-lg">
                        <div className="col-span-6">
                            <h5 className="text-xl font-medium">Информация о компании</h5>
                            {/* <p className="text-black/80 mt-1">После изменения этих данных потребуется модерация</p> */}
                            {/* <p className="text-black/80 mt-1">Данная информация будет отображаться в карточке поставщика</p> */}
                        </div>
                        <div className="sm:col-span-6 divide-y flex flex-col">
                            <div className="grid xl:grid-cols-6 items-start justify-between py-3">
                                <div className="text-black/50">Название компании:</div>
                                <div className="col-span-5 flex flex-row justify-between space-x-4">
                                    {
                                        isLoading ? <div className="h-6 w-1/3 bg-slate-100 rounded"></div> :
                                            <p>{profile.comp_name ? profile.comp_name : "Название не установлено"}</p>
                                    }
                                </div>
                            </div>

                            <div className="grid xl:grid-cols-6 items-start justify-between py-3">
                                <div className="text-black/50">БИН:</div>
                                <div className="col-span-5 flex flex-row justify-between space-x-4">
                                    {
                                        isLoading ? <div className="h-6 w-1/3 bg-slate-100 rounded"></div> :
                                            <p>{profile.comp_bin ? profile.comp_bin.replace(/(\d{3})(\d{3})(\d{3})(\d{3})/, "$1-$2-$3-$4") : "000000000000".replace(/(\d{3})(\d{3})(\d{3})(\d{3})/, "$1-$2-$3-$4")}</p>
                                    }
                                </div>
                            </div>

                            <div className="grid xl:grid-cols-6 items-start justify-between py-3">
                                <div className="text-black/50">Руководитель:</div>
                                <div className="col-span-5 flex flex-row justify-between space-x-4">
                                    {
                                        isLoading ? <div className="h-6 w-1/3 bg-slate-100 rounded"></div> :
                                            <p>{profile.manager_fullname ? profile.manager_fullname : ""}</p>
                                    }
                                </div>
                            </div>

                            <div className="grid xl:grid-cols-6 items-start justify-between py-3">
                                <div className="text-black/50">Фактический адрес:</div>
                                <div className="col-span-5 flex flex-row justify-between space-x-4">
                                    {
                                        isLoading ? <div className="h-6 w-1/3 bg-slate-100 rounded"></div> :
                                            <p>{profile.comp_address ? profile.comp_address : ""}</p>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-span-6 pt-6">
                            <h5 className="text-xl font-medium">Ответственное лицо</h5>
                            {/* <p className="text-black/80 mt-1">С помощью этих данных ваши клиенты сммогу связаться с вами</p> */}
                        </div>
                        <div className="sm:col-span-6 divide-y flex flex-col">
                            <div className="grid xl:grid-cols-6 items-start justify-between py-3">
                                <div className="text-black/50">ФИО:</div>
                                <div className="col-span-5 flex flex-row justify-between space-x-4">
                                    {
                                        isLoading ? <div className="h-6 w-1/3 bg-slate-100 rounded"></div> :
                                            <p>{profile.contact_fullname ? profile.contact_fullname : ""}</p>
                                    }
                                </div>
                            </div>
                            <div className="grid xl:grid-cols-6 items-start justify-between py-3">
                                <div className="text-black/50">Должность:</div>
                                <div className="col-span-5 flex flex-row justify-between space-x-4">
                                    {
                                        isLoading ? <div className="h-6 w-1/3 bg-slate-100 rounded"></div> :
                                            <p>{profile.contact_position ? profile.contact_position : ""}</p>
                                    }
                                </div>
                            </div>
                            <div className="grid xl:grid-cols-6 items-start justify-between py-3">
                                <div className="text-black/50">Контактная почта:</div>
                                <div className="col-span-5 flex flex-row justify-between space-x-4">
                                    {
                                        isLoading ? <div className="h-6 w-1/3 bg-slate-100 rounded"></div> :
                                            <p>{profile.contact_email ? profile.contact_email : ""}</p>
                                    }
                                </div>
                            </div>
                            <div className="grid xl:grid-cols-6 items-start justify-between py-3">
                                <div className="text-black/50">Контактный телефон:</div>
                                <div className="col-span-5 flex flex-row justify-between space-x-4">
                                    {
                                        isLoading ? <div className="h-6 w-1/3 bg-slate-100 rounded"></div> : <p>{phoneFormat(profile.contact_phone)}</p>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-span-6 pt-6 border-t mt-2">
                            <h5 className="text-xl font-medium">Безопасность</h5>
                            <p className="text-black/80 mt-1">С помощью этих данных вы сможете получить либо восстановить доступ к личному кабинету</p>
                        </div>

                        <div className="sm:col-span-6 divide-y flex flex-col">
                            <div className="grid xl:grid-cols-6 items-start justify-between py-3">
                                <div className="text-black/50">Почта:</div>
                                <div className="col-span-5 flex flex-row justify-between space-x-4">
                                    {
                                        isLoading ? <div className="h-6 w-1/3 bg-slate-100 rounded"></div> :
                                            <p>{profile.email ? profile.email : ""}</p>

                                    }
                                    <button onClick={() => { setChangeEmailModal(true) }} className="text-blue-500">Изменить</button>
                                </div>
                            </div>
                            <div className="grid xl:grid-cols-6 items-start justify-between py-3">
                                <div className="text-black/50">Пароль:</div>
                                <div className="col-span-5 flex flex-row justify-between space-x-4">
                                    {
                                        <p>************</p>
                                    }
                                    <button onClick={() => { setChangePasswordModal(true) }} className="text-blue-500">Изменить</button>
                                </div>
                            </div>
                        </div>

                        <div className="col-span-6 pt-6 border-t mt-2">
                            <h5 className="text-xl font-medium">Документы финансирования</h5>
                            <p className="text-black/80 mt-1">С помощью этих документов мы сможем одобрить финансирования для вашей компании</p>
                        </div>

                        <div className="col-span-6 grid grid-cols-1 sm:grid-cols-4 gap-4">
                            <DocumentBlockWithBorders name="Анкета" doc_info={docs["Анкета"]} tamplate_url="/Анкета Клиента.doc"></DocumentBlockWithBorders>
                            <DocumentBlockWithBorders name="Реестр поставок" doc_info={docs["Реестр поставок"]} tamplate_url="/Реестр к заявке на финансирование до ДФ.xlsx"></DocumentBlockWithBorders>
                            <DocumentBlockWithBorders name="Заявка" doc_info={docs["Заявка"]} tamplate_url="/Заявка на финансирование.docx"></DocumentBlockWithBorders>
                        </div>
                        <div className="col-span-6 flex flex-row justify-start mt-2">
                            <div onClick={() => { logout(); window.open("/app/auth", "_self") }} className="inline-flex items-center text-red-500 py-2 px-4 rounded-md text-lg border hover:bg-slate-50 duration-300 cursor-pointer">
                                <LogoutIcon className="w-8 h-8" />
                                <div className="ml-2 flex flex-col">
                                    <div>
                                        Завершить сеанс
                                    </div>
                                    <div className="text-black/70 text-sm -mt-1">
                                        Нажмите, чтобы завершить сеанс на данном устройстве
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <ChangingEmail active={changeEmailModal} setOpen={setChangeEmailModal}></ChangingEmail>
            <ChangePassword active={changePasswordModal} setOpen={setChangePasswordModal}></ChangePassword>
        </div>
    )
}

export default Profile;