import { useRef, useState, useCallback } from "react"
import ReactLoading from "react-loading"
import DocumentInLineWithTamplate from "../../components/forms/DocumentInLineWithTamplate"
import FormHook from "../../hooks/InputForm"
import { getCode, postRegistrationForm } from "../../libs/authContoller"
import validateEmail from "../../utils/emailValidator"
import ReCAPTCHA from "react-google-recaptcha"
import { testToken } from "../../libs/recaptchaController"

const RegistrationForm = () => {
    // const [formDocument, setFormDocument] = useState<any>([])
    const recaptchaRef = useRef<any>();

    const agreement = useRef<any>()

    const onReCAPTCHAChange = (value: any) => {
        console.log("Captcha value:", value);
    }

    const forms = {
        name: FormHook((e: string) => { return e.length > 0 }),
        bin: FormHook((e: string) => { return e.length == 12 }),
        managerFullname: FormHook((e: string) => { return e.length > 2 }),
        compAdress: FormHook((e: string) => { return e.length > 1 }),
        compEmail: FormHook((e: string) => { return validateEmail(e) }),
        contactPosition: FormHook((e: string) => { return e.length > 0 }),
        contactFullname: FormHook((e: string) => { return e.length > 1 }),
        contactPhone: FormHook((e: string) => { return e.length > 10 }),
        contactEmail: FormHook((e: string) => { return validateEmail(e) }),
    }

    const [compName, setCompName] = useState("")
    const [compBIN, setCompBIN] = useState("")
    const [managerFullname, setManagerFullname] = useState("")
    const [compAddress, setCompAdderss] = useState("")
    const [compEmail, setCompEmail] = useState("")
    const [contactPosition, setContactPosition] = useState("")
    const [contactFullname, setContactFullname] = useState("")
    const [contactPhone, setContactPhone] = useState("")
    const [contactEmail, setContactEmail] = useState("")
    const [recaptchaCode, setRecaptchaCode] = useState("")

    const [confirmingEmail, setConfirmingEmail] = useState(false)
    const [endMessage, setEndMessage] = useState(false)
    const [validationCode, setValitdationCode] = useState("")
    const [sending, setSending] = useState(false)

    const [digitsCode, setDigidsCode] = useState('')
    const [disabledCode, setDisabledCode] = useState(false)
    const [codeError, setCodeError] = useState(false)

    const handleSendButton = async () => {
        const token = await recaptchaRef.current.executeAsync();
        const response: any = await testToken({ "g-recaptcha-response": token })

        let check = forms.name.validationFunction()
        check &= forms.bin.validationFunction()
        check &= forms.managerFullname.validationFunction()
        check &= forms.compAdress.validationFunction()
        check &= forms.compEmail.validationFunction()
        check &= forms.contactPosition.validationFunction()
        check &= forms.contactFullname.validationFunction()
        check &= forms.contactPhone.validationFunction()
        check &= forms.contactEmail.validationFunction()
        check &= agreement.current.checked

        if (!check)
            return

        if (!response.data.code)
            return

        try {
            setCompName(forms.name.ref.current.value)
            setCompBIN(forms.bin.ref.current.value)
            setCompAdderss(forms.compAdress.ref.current.value)
            setCompEmail(forms.compEmail.ref.current.value)
            setManagerFullname(forms.managerFullname.ref.current.value)

            setContactFullname(forms.contactFullname.ref.current.value)
            setContactPosition(forms.contactPosition.ref.current.value)
            setContactEmail(forms.contactEmail.ref.current.value)
            setContactPhone(forms.contactPhone.ref.current.value.replace(" ", "").replace("+", "").replace("(", "").replace(")", "").replace("-", ""))

            const response = await getCode(forms.compEmail.ref.current.value)
            setValitdationCode(response.code)
            setConfirmingEmail(true)
        } catch (error) {
            console.log(error);
        }
    }

    const enterCode = (e: string) => {
        const code = e.replace("-", "")
        setCodeError(false)
        if (code.length <= 6)
            setDigidsCode(e.replace("-", ""))
        if (code.length == 6) {
            if (Number(code) != Number(validationCode)) {
                setCodeError(true)
                return
            } else {
                sendForm(code)
            }
        }
    }

    const formatCode = (value: string) => {
        return value.replace(/(\d{3})/, "$1-")
    }

    const sendForm = async (code: string) => {
        if (Number(code) != Number(validationCode))
            return
        setSending(true)
        const response = await postRegistrationForm(compName, compBIN, managerFullname, compAddress, compEmail, contactPosition, contactFullname, Number(contactPhone).toString(), contactEmail)
        setConfirmingEmail(false)
        setSending(false)
        setEndMessage(false)
        window.open("/app/auth", "_self")
    }

    return (
        <div className="pb-16 bg-gradient-to-br from-[#355a79] to-[#1C4B74] min-h-screen">
            <main className="relative z-20">
                <div className="mx-auto w-full max-w-5xl pt-8 sm:pt-16 overflow-hidden relative px-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col sm:flex-row sm:justify-between items-start w-full gap-4">
                        <div className="flex flex-col">
                            <div className="text-4xl font-medium text-white">
                                {!confirmingEmail && !endMessage ? "Регистрация" : ""}
                            </div>
                        </div>
                    </div>
                    {!confirmingEmail && !endMessage ?
                        <div className="w-full flex flex-col sm:grid grid-cols-1 sm:grid-cols-6 mt-8 gap-y-4 gap-x-4 bg-white px-8 py-6 rounded-lg relative overflow-hidden">
                            <div className="col-span-6">
                                <h5 className="text-2xl font-medium">Информация о компании</h5>
                                {/* <p className="text-black/80 mt-1">После изменения этих данных потребуется модерация</p> */}
                            </div>
                            <div className="sm:col-span-6 flex flex-row gap-16 mt-2">
                                <div className="flex flex-col items-start justify-between w-1/3">
                                    <div className="text-black/80 font-medium">Название компании:</div>
                                    <input ref={forms.name.ref} onChange={forms.name.onchange} type="text" className="mt-2 w-full bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                                </div>

                                <div className="flex flex-col items-start justify-between">
                                    <div className="text-black/80 font-medium">БИН:</div>
                                    <input ref={forms.bin.ref} onChange={forms.bin.onchange} type="text" className="mt-2 bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                                </div>
                            </div>

                            <div className="sm:col-span-6 flex flex-row gap-16">
                                <div className="flex flex-col items-start justify-between w-1/3">
                                    <div className="text-black/80 font-medium">Руководитель:</div>
                                    <input ref={forms.managerFullname.ref} onChange={forms.managerFullname.onchange} type="text" className="mt-2 w-full bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                                </div>
                            </div>

                            <div className="sm:col-span-6 flex flex-row gap-16">
                                <div className="flex flex-col items-start justify-between w-1/2">
                                    <div className="text-black/80 font-medium">Фактический адрес компании:</div>
                                    <input ref={forms.compAdress.ref} onChange={forms.compAdress.onchange} type="text" className="mt-2 w-full bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                                </div>
                                <div className="flex flex-col items-start justify-between w-1/3">
                                    <div className="text-black/80 font-medium">Электронная почта:</div>
                                    <input ref={forms.compEmail.ref} onChange={forms.compEmail.onchange} type="text" className="mt-2 w-full bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                                </div>
                            </div>

                            <div className="col-span-6 pt-6 border-t mt-4 ">
                                <h5 className="text-2xl font-medium">Ответственное лицо</h5>
                                {/* <p className="text-black/80 mt-1">С помощью этих данных ваши клиенты сммогу связаться с вами</p> */}
                            </div>
                            <div className="sm:col-span-6 flex flex-row gap-16 mt-2">
                                <div className="flex flex-col items-start justify-between w-1/3">
                                    <div className="text-black/80 font-medium">ФИО:</div>
                                    <input ref={forms.contactFullname.ref} onChange={forms.contactFullname.onchange} type="text" className="mt-2 w-full bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                                </div>
                            </div>
                            <div className="sm:col-span-6 flex flex-row gap-16">
                                <div className="flex flex-col items-start justify-between">
                                    <div className="text-black/80 font-medium">Должность:</div>
                                    <input ref={forms.contactPosition.ref} onChange={forms.contactPosition.onchange} type="text" className="mt-2 w-full bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                                </div>
                            </div>
                            <div className="sm:col-span-6 flex flex-row gap-16">
                                <div className="flex flex-col items-start justify-between w-1/4">
                                    <div className="text-black/80 font-medium">Контактный телефон:</div>
                                    <input ref={forms.contactPhone.ref} onChange={forms.contactPhone.onchange} type="text" className="mt-2 w-full bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                                </div>
                                <div className="flex flex-col items-start justify-between w-1/3">
                                    <div className="text-black/80 font-medium">Контактная почта:</div>
                                    <input ref={forms.contactEmail.ref} onChange={forms.contactEmail.onchange} type="text" className="mt-2 w-full bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                                </div>
                            </div>

                            {/* <div className="col-span-6 pt-6 border-t mt-4 ">
                                <h5 className="text-2xl font-medium">Документы</h5>
                            </div>
                            <div className="sm:col-span-6 flex flex-col gap-4 pb-4">
                                <DocumentInLineWithTamplate name="Анкета" tamplate_url="/Анкета Клиента.doc" files={formDocument} setFiles={setFormDocument}></DocumentInLineWithTamplate>
                               </div> */}


                            <div className="flex flex-row col-span-6 border-t pt-8 justify-between items-end">
                                <div className="flex flex-row justify-start items-center gap-2 w-2/3 self-start -mt-1">
                                    {/* <input
                                    id="candidates"
                                    name="candidates"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                                <div>
                                    Terms and Conditions
                                </div> */}
                                    <div className="flex flex-row gap-4 items-center">
                                        <input ref={agreement} type="checkbox" className="border border-gray-100 bg-slate-100 checked:hover:bg-[#006C93] checked:bg-[#006C93] focus:ring-[#006C93] checked:focus:bg-[#006C93] h-5 w-5 rounded" />
                                        <div className="">Я соглашаюсь с <a className="text-blue-700" target="_blank" href="/Соглашение на обработку персональных данных.docx">политикой в отношении персональных данных</a></div>
                                        {/* <p>
                                            Региcтрируясь на нашем сайте вы соглашаетесь с
                                            <a href="" target="_blank" className="text-[#02718D]"> Правилами использования</a>,
                                            <a href="" target="_blank" className="text-[#02718D]"> Политикой конфиденциальности</a> и
                                            <a href="" target="_blank" className="text-[#02718D]"> Соглашении об обработке данных</a>
                                        </p> */}
                                    </div>
                                </div>
                                <div>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        size="invisible"
                                        sitekey={"6LfYAMYnAAAAACKv5DBGP3lQy-wssZxMNUoSYZkC"}
                                        onChange={onReCAPTCHAChange}
                                    />
                                    <button onClick={handleSendButton} className="bg-[#006C93] px-4 py-2 rounded text-white text-lg">Зарегистрироваться</button>
                                </div>
                            </div>
                            {
                                sending ?
                                    <div className="absolute w-full h-full z-[99] flex flex-col top-0 left-0 justify-center items-center bg-black/20">
                                        <div className="w-16 h-16">
                                            <ReactLoading type="spin" color="#fff"></ReactLoading>
                                        </div>
                                    </div>
                                    : <></>
                            }
                        </div> : <></>
                    }
                    {confirmingEmail && !endMessage ?
                        <div className="bg-white px-16 py-16 rounded-xl mt-8 max-w-xl mx-auto flex flex-col">
                            <div className="h-32 aspect-1 self-center">
                                <img src="/logo.svg" alt="" className="w-full h-full" />
                            </div>
                            <div className="text-center text-3xl mt-4 font-medium text-[#11436e]">e-Lab Factor</div>
                            <div className="text-center text-lg text-[#11436e]/75">Факторинговая компания</div>
                            <div className="text-center text-base mt-12 flex flex-col gap-1">
                                <div>Здравствуйте!</div>
                                <div>Мы рады приветствовать Вас и желаем Вам процветания!</div>
                                <div className="text-lg mt-4">Пожалуйства, введите код, отправленный на {compEmail},</div>
                            </div>
                            {/* <div className="text-center mt-8 text-lg">
                                Введите ваш код из 6 цифр:
                            </div> */}
                            <div className="mt-4 flex flex-col items-center">
                                {/* <div className="font-medium">Почта</div> */}
                                <input value={formatCode(digitsCode)} onChange={e => { enterCode(e.target.value) }} type="text" placeholder="******" className={`placeholder:font-normal w-2/3 mt-2 text-center bg-gray-100 text-2xl flex-1 border-1 ${codeError ? "border-red-600 focus:border-red-600" : "focus:border-gray-100 border-transparent"} rounded py-2.5 px-4 text-gray-900 placeholder:text-gray-400 focus:border-gray-100 focus:ring-0 sm:leading-6`} />

                                {/* <input disabled={disabledCode} ref={inputRefs[0]} key='0' type="number" onKeyDown={e => handleKeyDown(e, 0)} onChange={e => { handleInputCode(e, 0) }} className={"rounded text-4xl text-center py-4 block flex-1 border-0  px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6 bg-gray-100 disabled:text-gray-400" + (codeError ? "border border-red-600" : "")} maxLength={1} />
                                <input disabled={disabledCode} ref={inputRefs[1]} key='1' type="number" onKeyDown={e => handleKeyDown(e, 1)} onChange={e => { handleInputCode(e, 1) }} className={"rounded text-4xl text-center py-4 block flex-1 border-0  px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6 bg-gray-100 disabled:text-gray-400" + (codeError ? "border border-red-600" : "")} maxLength={1} />
                                <input disabled={disabledCode} ref={inputRefs[2]} key='2' type="number" onKeyDown={e => handleKeyDown(e, 2)} onChange={e => { handleInputCode(e, 2) }} className={"rounded text-4xl text-center py-4 block flex-1 border-0  px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6 bg-gray-100 disabled:text-gray-400" + (codeError ? "border border-red-600" : "")} maxLength={1} />
                                <input disabled={disabledCode} ref={inputRefs[3]} key='3' type="number" onKeyDown={e => handleKeyDown(e, 3)} onChange={e => { handleInputCode(e, 3) }} className={"rounded text-4xl text-center py-4 block flex-1 border-0  px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6 bg-gray-100 disabled:text-gray-400" + (codeError ? "border border-red-600" : "")} maxLength={1} />
                                <input disabled={disabledCode} ref={inputRefs[4]} key='4' type="number" onKeyDown={e => handleKeyDown(e, 4)} onChange={e => { handleInputCode(e, 4) }} className={"rounded text-4xl text-center py-4 block flex-1 border-0  px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6 bg-gray-100 disabled:text-gray-400" + (codeError ? "border border-red-600" : "")} maxLength={1} />
                                <input disabled={disabledCode} ref={inputRefs[5]} key='5' type="number" onKeyDown={e => handleKeyDown(e, 5)} onChange={e => { handleInputCode(e, 5) }} className={"rounded text-4xl text-center py-4 block flex-1 border-0  px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6 bg-gray-100 disabled:text-gray-400" + (codeError ? "border border-red-600" : "")} maxLength={1} /> */}
                            </div>
                            <div className="text-center mt-6 text-lg">
                                для продолжения регистрации на портале Факторинговой компании e-Lab Factor.
                            </div>
                            {/* {codeError ? <div className="text-red-600 text-lg mt-4 text-center">Вы ввели неправильный код</div> : <></>} */}
                        </div> : <></>
                    }
                    {endMessage ?
                        <div className="bg-white px-16 py-16 rounded-xl mt-8 max-w-2xl mx-auto flex flex-col">
                            <div className="h-32 aspect-1 self-center">
                                <img src="/logo.svg" alt="" className="w-full h-full" />
                            </div>
                            <div className="text-center text-3xl mt-4 font-medium text-[#11436e]">e-Lab Factor</div>
                            <div className="text-center text-lg text-[#11436e]/75">Факторинговая компания</div>
                            <div className="text-center text-lg mt-12">
                                Ваша анкета успешно была отправлена на рассмотрение. <br />Мы свяжемся с вами в ближайшее время. <br /><br />
                                В случае одобрения вашей заявки вы получите на почту данные от вашего личного кабинета.
                            </div>
                            <a href="/" className="text-center mt-16 text-blue-600">Вернуться на главную страницу</a>
                        </div> : <></>
                    }
                </div>
            </main>
        </div>
    )
}

export default RegistrationForm

