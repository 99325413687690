import axios from 'axios';
const SERVER_URL = "https://elabfactor.com";

const sendForm = async (data: any) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/forms/sendQuestion.php`;
    try {
        let response = await axios.post(LOGIN_ENDPOINT, data);

        return response
    } catch (e) {
        console.log(e);
    }
}

export { sendForm }