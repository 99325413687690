import axios from 'axios';
const SERVER_URL = "https://elabfactor.com";

const requestCode = async (data: any) => {
    const ENDPOINT = `${SERVER_URL}/api/codes/requestCode.php`;
    try {
        let response = await axios.post(ENDPOINT, data, {
            headers: {
                "Content-Type" : "multipart/form-data",
            }
        });

        return response;
    } catch (e: any) {
        console.log(e.response!.data.message);
    }
}

const changeEmailWithCode = async (data: any) => {
    const ENDPOINT = `${SERVER_URL}/api/profile/changeEmailWithCode.php`;
    try {
        let response = await axios.post(ENDPOINT, data, {
            headers: {
                "Content-Type" : "multipart/form-data",
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },
        });
        
        return response
    } catch (e) {
        console.log(e);
    }
}

export { requestCode, changeEmailWithCode }