import { useEffect, useState, useRef } from "react";
import ReactLoading from "react-loading";
import { checkauth, logout, signin } from "../../libs/authContoller";
import validateEmail from "../../utils/emailValidator";
import ReCAPTCHA from "react-google-recaptcha"
import { testToken } from "../../libs/recaptchaController"

const Auth = () => {
    const recaptchaRef = useRef<any>();
    const onReCAPTCHAChange = (value: any) => {
        console.log("Captcha value:", value);
    }

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(true)
    const [localLoading, setLocalLoading] = useState(false)

    const [errorEmail, setErrorEmail] = useState(false)
    const [errorPassword, setErrorPassword] = useState(false)

    document.title = "Авторизация"

    const check = async () => {
        setLoading(true)
        if (localStorage.getItem('access_token') != null)
            if (await checkauth()) {
                window.open("/app/profile", "_self")
            } else {
                logout()
                setLoading(false)
            }
        else (
            setLoading(false)
        )
    }

    useEffect(() => {
        check()
    }, [])

    const handleLoginButton = async () => {
        if (validateEmail(email)) {
            setLocalLoading(true)
            const token = await recaptchaRef.current.executeAsync();
            const recaptchaResponse: any = await testToken({ "g-recaptcha-response": token })

            if (!recaptchaResponse.data.code) {
                setLocalLoading(false)
                return
            }

            if (localStorage.getItem('access_token') == null) {
                const response: any = await signin({ "email": email, "password": password })
                if (response.data.error === 0 && response.data.jwt && response.data.expireAt) {
                    window.open('/app/profile', "_self")
                }
                if (response.data.error === 1) {
                    setErrorPassword(true)
                }
                if (response.data.error === 2) {
                    setErrorEmail(true)
                }
            }
            setLocalLoading(false)
        }
    }

    if (loading)
        return (
            <div className="absolute w-full h-full z-[99] flex flex-col top-0 left-0 justify-center items-center bg-gradient-to-br from-[#355a79] to-[#1C4B74]">
                <div className="w-16 h-16">
                    <ReactLoading type="spin" color="#fff"></ReactLoading>
                </div>
            </div>)

    return (
        <div className="h-screen flex flex-row justify-center items-center px-4 sm:px-6 lg:px-8 bg-gradient-to-br from-[#355a79] to-[#1C4B74]">
            <div className="sm:max-w-lg bg-white rounded-lg w-full flex flex-col p-8">
                <div className="h-32 aspect-1 self-center">
                    <img src="/logo.svg" alt="" className="w-full h-full" />
                </div>
                <div className="text-center text-3xl mt-4 font-medium text-[#11436e]">e-Lab Factor</div>
                <div className="text-center text-lg text-[#11436e]/75">Факторинговая компания</div>
                {/* <div className="text-center mt-8">
                    Вы можете войти в учетную запись
                </div> */}
                <div className="mt-8">
                    {/* <div className="font-medium">Почта</div> */}
                    <input disabled={localLoading} placeholder="Почта" value={email} onChange={e => { setEmail(e.target.value); setErrorEmail(false) }} className={`mt-2 block flex-1 border-1 ${errorEmail ? "border-red-600 focus:border-red-600" : "border-transparent focus:border-transparent"} py-2 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6 bg-gray-100 w-full rounded`} type="text" />
                    <input disabled={localLoading} placeholder="Пароль" value={password} onChange={e => { setPassword(e.target.value); setErrorPassword(false) }} className={`mt-2 block flex-1 border-1 ${errorPassword ? "border-red-600 focus:border-red-600" : "border-transparent focus:border-transparent"}  py-2 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6 bg-gray-100 w-full rounded`} type="password" />
                </div>
                <div className="mt-4">
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={"6LfYAMYnAAAAACKv5DBGP3lQy-wssZxMNUoSYZkC"}
                        onChange={onReCAPTCHAChange}
                    />
                    <button disabled={localLoading} onClick={handleLoginButton} className="disabled:bg-slate-300 w-full bg-gradient-to-br from-[#355a79] to-[#1C4B74] text-white rounded py-2 enabled:hover:opacity-[85%] duration-300">Войти</button>
                </div>
                <div className="text-center text-sm mt-8">
                    <p>Восстановить доступ?</p>
                    <a href="/app/password-reset" className="text-[#11436e] font-medium">Вы можете восстановить доступ по данной ссылке.</a>
                </div>
                <div className="text-center text-sm mt-8">
                    <p>Вы ещё не подала заявку?</p>
                    <a href="/app/registration" className="text-[#11436e] font-medium">Вы можете подать заявку на добавление компании <span className=" whitespace-nowrap">по данной ссылке.</span></a>
                </div>
                {/* <div className="text-center text-sm mt-8">
                    <p>
                        Мы будем обрабатывать Ваши данные в соответствии с
                    </p>
                    <p>
                        <a href="" target="_blank" className="text-[#11436e] font-medium"> Правилами использования</a>,
                        <a href="" target="_blank" className="text-[#11436e] font-medium"> Политикой конфиденциальности</a> и
                        <a href="" target="_blank" className="text-[#11436e] font-medium"> Соглашении об обработке данных</a>
                    </p>
                </div> */}
            </div>

        </div>
    )
}



export default Auth;