import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon, PlusSmIcon } from "@heroicons/react/outline";
import { FC, useEffect, useState } from "react";
import AppHeader from "../../components/appHeader/AppHeader";
import { checkAndOut } from "../../libs/authContoller";
import { getDeliveries, getDeliveriesCount } from "../../libs/deliveriesController";
import { ShortTimestampToString } from "../../utils/dates";
import AddDelivery from "./modal/addDelivery/AddDelivery";
import OpenDelivery from "./modal/openDelivery/OpenDelivery";

const formatNumber = (value: string) => {
    return value.split("").reverse().join("").replace(/(.{3})/g, "$1 ").split("").reverse().join("").trim()
}

const Delivery: FC<{ id: number, name: string, bin: string, cost: string, delivery_date: string, setOpenedDelivery: any }> = ({ id, name, bin, cost, delivery_date, setOpenedDelivery }) => {
    return (
        <tr key={`Delivery ${id}`} className="px-4 duration-300 bg-white hover:bg-gray-50 cursor-pointer" onClick={() => setOpenedDelivery({ state: true, id: id })}>
            <td className="font-medium py-4 px-4 whitespace-nowrap">{name}</td>
            <td className="text-black/70 py-4 px-4 whitespace-nowrap">{bin.replace(/(\d{3})(\d{3})(\d{3})(\d{3})/, "$1-$2-$3-$4")}</td>
            <td className="text-black/70 py-4 px-4 whitespace-nowrap hidden sm:block">{ShortTimestampToString(Number(delivery_date))}</td>
            <td className="text-black/70 py-4 px-4 whitespace-nowrap">{formatNumber(cost.toString())}</td>
        </tr>
    )
}

const Deliveries = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const searchClient = urlParams.get('client');

    const [deliveries, setDeliveries] = useState([]);
    const [searchValue, setSearchValue] = useState(searchClient || "")
    const [searchFinalValue, setSearchFinalValue] = useState(searchClient || "")
    const [pagesCount, setPagesCount] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [isOpenAddDeliveryModal, setOpenAddDeliveryModal] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const [openedDelivery, setOpenedDelivery] = useState({ state: false, id: 0 })
    document.title = "Поставки"

    document.body.style.overflow = isOpenAddDeliveryModal || openedDelivery.state ? 'hidden' : 'auto'

    const updateSearchValue = async (e: any) => {
        setSearchValue(e.target.value)
        const temp = e.target.value
        setTimeout(function () {
            if (temp == e.target.value)
                setSearchFinalValue(e.target.value)
        }, 1000);
    }

    useEffect(() => {
        checkAndOut()
    }, [])

    const renderConsumers = async () => {
        setDeliveries([])
        setLoading(true)
        const temp_clients: any = [];
        const response: any = await getDeliveries(searchValue, currentPage - 1);
        response.data.map((element: any) => {
            temp_clients.push(<Delivery setOpenedDelivery={setOpenedDelivery} key={element.delivery_id} name={element.client_name} bin={element.client_bin} id={element.delivery_id} cost={element.cost} delivery_date={element.deliveried_at}></Delivery>)
        });
        setLoading(false)
        setDeliveries(temp_clients);
    }

    const renderCount = async () => {
        const response: any = await getDeliveriesCount(searchValue)
        setPagesCount(Math.ceil(response.data.count / 10))
    }

    useEffect(() => {
        renderConsumers();
        renderCount();
    }, [searchFinalValue])

    useEffect(() => {
        setLoading(true)
        if (!isLoading)
            renderConsumers()
    }, [currentPage])

    return (
        <div className="pb-16 relative min-h-screen bg-slate-200">
            <div className="absolute top-0 left-0 w-full h-80 bg-gradient-to-br from-[#355a79] to-[#1C4B74]">
            </div>
            <header className="z-40 relative">
                <AppHeader></AppHeader>
            </header>
            <main className="pb-16 relative z-10">
                <div className="max-w-7xl w-full mx-auto gap-x-4 mt-8 sm:mt-8 px-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col sm:flex-row sm:justify-between items-start w-full gap-4">
                        <div className="flex flex-col">
                            <div className="text-4xl font-medium text-white">
                                Поставки
                            </div>
                            <div className="w-full sm:w-auto">
                                {/* <input value={searchValue} onChange={e => setSearchValue(e.target.value)} className="h-10 mt-4 text-white w-full sm:w-auto rounded-full bg-transparent border border-white/20 placeholder:text-white px-4 focus:ring-0 block " placeholder="Поиск"></input> */}
                                <input value={searchValue} onChange={e => updateSearchValue(e)} type="text" placeholder="Поиск" className="duration-300 transition-colors placeholder:text-white/40 focus:placeholder:text-white/80 w-full sm:w-auto mt-4 border border-white/20 bg-transparent text-white rounded-full py-2 px-4 focus:border-white/50 focus:ring-0 sm:leading-6" />
                            </div>
                        </div>
                        <div>
                            <div onClick={() => setOpenAddDeliveryModal(true)} className="items-center flex flex-row bg-[#f9d370] text-[#12436E] rounded-full text-base font-medium pl-4 pr-6 py-2 hover:bg-[#D3AE50] duration-300 cursor-pointer">
                                <PlusSmIcon className="h-6 mr-2"></PlusSmIcon>
                                Добавить
                            </div>
                        </div>
                    </div>
                    <div className="col-span-3 w-full bg-white rounded-lg overflow-hidden mt-8">
                        <table className="col-span-3 w-full bg-white rounded-lg overflow-hidden text-left">
                            <thead className="bg-slate-100">
                                <tr className="">
                                    <th scope="col" className="font-medium py-4 px-4">Контрагент</th>
                                    <th scope="col" className="font-medium py-4 px-3.5">BIN</th>
                                    <th scope="col" className="font-medium py-4 px-3.5 hidden sm:block">Дата поставки</th>
                                    <th scope="col" className="font-medium py-4 px-3.5">Сумма</th>
                                    {/* <th scope="col" className="font-medium py-4 px-4 hidden sm:block">Дата ближайшего платежа</th> */}
                                </tr>
                            </thead>
                            {
                                !isLoading && deliveries.length == 0 ?
                                    <tr>
                                        <td colSpan={4} className="text-center py-32 text-xl px-16 sm:px-0">
                                            По вашему запросу ничего не найдено
                                        </td>
                                    </tr>
                                    :
                                    <tbody className="divide-y divide-gray-200">
                                        {
                                            isLoading ? Array.from(Array(10).keys()).map((element) => {
                                                return (
                                                    <tr key={`Loading Contract ${element}`} className="px-4 duration-300 bg-white hover:bg-gray-50 cursor-pointer">
                                                        <td colSpan={4} className="font-medium py-4 px-4 whitespace-nowrap"><div className="bg-gray-100 rounded text-transparent ">namenamename</div></td>
                                                        {/* <td className="text-black/70 py-4 px-4 whitespace-nowrap hidden sm:block">65</td> */}
                                                    </tr>
                                                )
                                            }) : deliveries.map((elem) => elem)
                                        }
                                    </tbody>
                            }
                        </table>
                    </div>
                </div>

                {
                    pagesCount != 1 ?
                        <div className="max-w-7xl w-full mx-auto flex flex-col-reverse sm:flex-row gap-x-4 mt-8 sm:mt-16 px-4 sm:px-6 lg:px-8">
                            <nav className="w-full border-t border-gray-200 flex items-center justify-between sm:px-0">
                                <div className="-mt-px w-0 flex-1 flex">
                                    {
                                        currentPage != 1 ? <a onClick={() => { setCurrentPage(page => { return page - 1 }) }} className="cursor-pointer px-4 inline-flex items-center text-sm font-medium duration-300 text-gray-500 hover:text-gray-700 py-2 hover:bg-slate-50/50 rounded-full" >
                                            <ArrowNarrowLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            Предыдущая
                                        </a> : <></>
                                    }

                                </div>
                                <div className="flex md:-mt-px gap-1 sm:gap-2">
                                    {
                                        Array.from(Array(3).keys()).reverse().map((element) => {
                                            if (currentPage - 1 - element > 0)
                                                return (
                                                    <a key={`Page ${currentPage - 1 - element}`} onClick={() => setCurrentPage(currentPage - 1 - element)} className="hidden sm:inline-flex text-gray-500 rounded-full cursor-pointer duration-300 hover:text-gray-700 hover:bg-slate-50/50 px-4 aspect-1 items-center text-sm font-medium" aria-current="page">
                                                        {currentPage - 1 - element}
                                                    </a>
                                                )
                                        })
                                    }
                                    <a key={`Page ${currentPage}`} className="text-black rounded-full bg-slate-50 px-4 aspect-1 inline-flex items-center text-sm font-medium" aria-current="page">
                                        {currentPage}
                                    </a>
                                    {
                                        Array.from(Array(3).keys()).map((element) => {
                                            if (currentPage + 1 + element <= pagesCount)
                                                return (
                                                    <a key={`Page ${currentPage + 1 + element}`} onClick={() => setCurrentPage(currentPage + 1 + element)} className="hidden sm:inline-flex text-gray-500 rounded-full cursor-pointer duration-300 hover:text-gray-700 hover:bg-slate-50/50 px-4 aspect-1 items-center text-sm font-medium" aria-current="page">
                                                        {currentPage + 1 + element}
                                                    </a>
                                                )
                                        })
                                    }
                                </div>
                                <div className="-mt-px w-0 flex-1 flex justify-end">
                                    {
                                        currentPage < pagesCount ?
                                            <a onClick={() => { setCurrentPage(page => { return page + 1 }) }} className="cursor-pointer px-4 inline-flex items-center text-sm font-medium duration-300 text-gray-500 hover:text-gray-700 py-2 hover:bg-slate-50/50 rounded-full" >
                                                Следующая
                                                <ArrowNarrowRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </a> : <></>
                                    }
                                </div>
                            </nav>
                        </div> : <></>
                }

            </main>
            <OpenDelivery deliveryStates={openedDelivery} setDeliveryStates={setOpenedDelivery}></OpenDelivery>
            <AddDelivery active={isOpenAddDeliveryModal} setOpen={setOpenAddDeliveryModal} renderFunction={renderConsumers}></AddDelivery>
        </div>
    )
}

export default Deliveries;