import axios from 'axios';
const SERVER_URL = "https://elabfactor.com";

const updateName = async (data: any) => {
    const ENDPOINT = `${SERVER_URL}/api/profile/updateName.php`;
    try {
        let response = await axios({
            method: 'post',
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },
            url: ENDPOINT,
            data: data
        });
        console.log(response)
    } catch (e) {
        console.log(e);
    }
}

const updatePassword = async (data: any) => {
    const ENDPOINT = `${SERVER_URL}/api/profile/changePassword.php`;
    try {
        let response = await axios.post(ENDPOINT, data, {
            headers: {
                "Content-Type" : "multipart/form-data",
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },
        });

        console.log(response)

        if (response.status === 200 && response.data.jwt && response.data.expireAt) {
            let jwt = response.data.jwt;
            let expire_at = response.data.expireAt;

            localStorage.setItem("access_token", jwt);
            localStorage.setItem("expire_at", expire_at);

            window.open('/app/profile', "_self")
        }
        // window.open('/app/profile', "_self")
    } catch (e) {
        console.log(e);
    }
}


const getInfoProfile = async () => {
    const ENDPOINT = `${SERVER_URL}/api/profile/getProfileInfo.php`;

    try {

        let response = await axios.get(ENDPOINT, {
            headers: {
                'content-type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },
        });
        
        return response.data;

    } catch (e: any) {
        console.log(e.response!.data.message);
        if (e.response.data.message == "Access denied.")
            window.open("/app/auth", "_self")
    }
}

export { updatePassword, getInfoProfile } 