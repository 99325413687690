import { FC, useRef, useState, useEffect, useContext } from "react"
import ReactLoading from 'react-loading';
import { changeEmailWithCode, requestCode } from "../../libs/verificationCodeController";
import validateEmail from "../../utils/emailValidator";
import FormHook from "../../hooks/InputForm";
import { logout } from "../../libs/authContoller";

const ChangingEmail: FC<{ active: boolean, setOpen: any }> = ({ active, setOpen }) => {
    const [verificationNewEmail, setVeirifcationNewEmail] = useState(false)

    const [codeId, setCodeId] = useState(0)

    const [digitsCode, setDigidsCode] = useState('')
    const [codeError, setCodeError] = useState(false)
    const [validationCode, setValidationCode] = useState()

    const emailForm = FormHook(validateEmail)
    const [email, setEmail] = useState("")

    const handleChangeButton = async () => {
        if (!emailForm.validationFunction())
            return
        const response: any = await requestCode({ "email": email });
        setCodeId(response.data.code_id)
        setVeirifcationNewEmail(true)
    }

    const sendCode = async (e: string) => {
        const response: any = await changeEmailWithCode({ "code": e, "code_id": codeId, "email": email})
        if (response.status == 200) {
            logout()
            window.open("/app/auth", "_self")
            // setOpen(false)
        } else {
            setCodeError(true)
        }
    }

    const enterCode = (e: string) => {
        const code = e.replace("-", "")
        setCodeError(false)
        if (code.length <= 6)
            setDigidsCode(code)
        if (code.length == 6) {
            sendCode(code)
        }
    }

    const formatCode = (value: string) => {
        return value.replace(/(\d{3})/, "$1-")
    }

    if (!active)
        return <></>

    if (!verificationNewEmail)
        return (
            <div className="bg-black/40 w-screen h-screen fixed top-0 left-0 flex flex-col justify-start sm:justify-center sm:items-center pt-8 sm:pt-16 z-40 overflow-y-auto">
                <div className='w-full sm:max-w-xl px-4 sm:px-6 lg:px-8 pb-8'>
                    <div className="bg-white p-4 rounded-xl flex flex-col sm:grid sm:grid-cols-4 gap-2 overflow-hidden relative">
                        <div className="text-2xl font-medium col-span-4">Изменение почты</div>
                        {/* <div className="mt-2 text-black/60 col-span-4">Данный договор будет отправлено оппоненту для подтверждения</div> */}
                        <div className="mt-2 col-span-4 relative">
                            <div className="col-span-2">
                                <label className="block font-medium leading-6 text-gray-900 mt-2">Новая почта</label>
                                <input ref={emailForm.ref} value={email} onChange={e => { setEmail(e.target.value); emailForm.onchange() }} type="text" className="mt-2 w-full bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                            </div>
                        </div>
                        <div className="flex flex-row justify-end col-span-4 mt-2 gap-4">
                            <button onClick={() => { setOpen(false) }} className="bg-gray-200 text-black py-2 px-4 rounded-md">Отменить</button>
                            <button onClick={handleChangeButton} className="bg-[#006C93] text-white py-2 px-4 rounded-md">Изменить</button>
                        </div>
                    </div>
                </div>
            </div>
        )

    return (
        <div className="bg-black/40 w-screen h-screen fixed top-0 left-0 flex flex-col justify-start sm:justify-center sm:items-center pt-8 sm:pt-16 z-40 overflow-y-auto">
            <div className='w-full sm:max-w-xl px-4 sm:px-6 lg:px-8 pb-8'>
                <div className="bg-white p-4 rounded-xl flex flex-col sm:grid sm:grid-cols-4 gap-2 overflow-hidden relative">
                    <div className="text-2xl font-medium col-span-4">Изменение почты</div>
                    <div className=" text-black/60 col-span-4">Мы отправили одноразовый код на {email}.</div>
                    <div className="mt-4 col-span-4 relative">
                        <div className="col-span-2">
                            <label className="block font-medium leading-6 text-gray-900 mt-2">Введите код</label>
                            <input value={formatCode(digitsCode)} onChange={e => { enterCode(e.target.value) }} placeholder="******" type="text" className="mt-2 w-full bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangingEmail