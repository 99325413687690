import { useRef } from "react"

function FormHook(validator: any) {
    const ref = useRef<any>()

    const validate = () => {
        if (!validator(ref.current?.value)) {
            ref.current?.classList.replace("border-transparent", "border-red-600")
        }

        return validator(ref.current?.value)
    }

    const removeBorder = () => (
        ref.current?.classList.replace("border-red-600", "border-transparent")
    )

    return { ref: ref, validationFunction: validate, onchange: removeBorder }
}

export default FormHook