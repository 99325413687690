import axios from 'axios';
const SERVER_URL = "https://elabfactor.com";

const getPaymentsByDate = async (date: any) => {
    const ENDPOINT = `${SERVER_URL}/api/payments/getCalendarPayments.php?date=${date}`;

    try {
        let response = await axios.get(ENDPOINT, {
            headers: {
                'content-type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
        
        return response
    } catch (e: any) {
        console.log(e.response!.data.message);
        if (e.response.data.message == "Access denied.")
            window.open("/app/auth", "_self")
    }
}

const getPaymentsByMonth = async (date: any, year: any) => {
    const ENDPOINT = `${SERVER_URL}/api/payments/getCalendarPaymentsByMonth.php?month=${date}&year=${year}`;

    try {
        let response = await axios.get(ENDPOINT, {
            headers: {
                'content-type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },
        })

        return response
    } catch (e: any) {
        console.log(e.response!.data.message);
        if (e.response.data.message == "Access denied.")
            window.open("/app/auth", "_self")
    }
}

export { getPaymentsByDate, getPaymentsByMonth }