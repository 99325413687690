import { FC, useState, useEffect } from "react"
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { DateToShortTimestamp } from "../../utils/dates";

const ModalDatapicker: FC<{ active: boolean, setOpen: any, selectedDate: Number, setDate: any }> = ({ active, setOpen, selectedDate, setDate }) => {
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    const [firstDay, setFirstDay] = useState(new Date(y, m, 1))
    const [lastDay, setLastDay] = useState(new Date(y, m + 1, 1))
    const [currentMonth, setCurrentMonth] = useState(date.getMonth())
    const [currentYear, setCurrentYear] = useState(date.getFullYear())
    const [dates, setDates] = useState<Number>(selectedDate)

    const month = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]

    useEffect(() => {
        setDates(selectedDate)
    }, [selectedDate])

    const handleBackMonth = () => {
        if (currentMonth == 0) {
            setCurrentYear(currentYear - 1)
            setCurrentMonth(11)
        } else {
            setCurrentMonth(currentMonth - 1)
        }

    }

    const handleNextMonth = () => {
        if (currentMonth == 11) {
            setCurrentYear(currentYear + 1)
            setCurrentMonth(0)
        } else {
            setCurrentMonth(currentMonth + 1)
        }
    }

    useEffect(() => {
        setFirstDay(new Date(currentYear, currentMonth, 1))
        setLastDay(new Date(currentYear, currentMonth + 1, 1))
    }, [currentYear, currentMonth])

    const handleSelectDate = (elem: any) => {
        if (elem.target.classList.contains('bg-transparent')) {
            elem.target.classList.replace('bg-transparent', 'bg-[#12436E]')
            elem.target.classList.replace('text-black', 'text-white')
            const additionalDate = (Date.parse(`${currentYear}-${(currentMonth + 1).toString().padStart(2, '0')}-${elem.target.innerHTML.padStart(2, '0')}T00:00:00.000Z`) / 100000)
            setDates(additionalDate)
            setDate(additionalDate)
        }
        setOpen(false)
    }

    if (!active)
        return (<></>)

    return (
        <>
            <div className="fixed top-0 left-0 w-screen h-screen bg-black/40 flex flex-col justify-start items-center">
                <div className="max-w-md bg-white w-full mt-32 p-4 rounded-lg">
                    <div className="bg-slate-100 rounded-md text-xl flex flex-row justify-between py-2 px-2 items-center">
                        <ChevronLeftIcon onClick={handleBackMonth} className="text-black h-6"></ChevronLeftIcon>
                        <div className="flex flex-col items-center">
                            <div>{month[currentMonth]}</div>
                            <div className="text-sm">{currentYear}</div>
                        </div>
                        <ChevronRightIcon onClick={handleNextMonth} className="text-black h-6"></ChevronRightIcon>
                    </div>
                    <div className="grid grid-cols-7 mt-6 gap-2">
                        {
                            ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"].map((elem) => {
                                return (
                                    <div className="text-center">
                                        {elem}
                                    </div>
                                )
                            })
                        }
                        {
                            Array.from(Array(firstDay.getDay() - 1 < 0 ? 6 : firstDay.getDay() - 1).keys()).map(() => {
                                return (<div></div>)
                            })
                        }
                        {
                            Array.from(Array((lastDay.getTime() / (24 * 60 * 60) - firstDay.getTime() / (24 * 60 * 60)) / 1000).keys()).map((elem) => {
                                if (dates != DateToShortTimestamp(currentYear, currentMonth, elem + 1))
                                    return (<div key={elem + 1} onClick={(e) => { handleSelectDate(e) }} className="text-black duration-300 text-center border font-medium rounded-md py-1 cursor-pointer bg-transparent">{elem + 1}</div>)
                                else
                                    return (<div key={elem + 1} onClick={(e) => { handleSelectDate(e) }} className="text-white duration-300 text-center border font-medium rounded-md py-1 cursor-pointer bg-[#12436E]">{elem + 1}</div>)
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModalDatapicker