import { FC, useRef, useState, useEffect } from "react"
import { DateToShortTimestamp, ShortTimestampToString } from "../../../../utils/dates";
import { addMoreFiles, getDeliveryInfo } from "../../../../libs/deliveriesController";
import { XIcon } from "@heroicons/react/outline";
import MultipleDocumentInLineWithAddingNew from "../../../../components/forms/MultipleDocumentInLineWithAddingNew";
import ReactLoading from "react-loading"

const OpenDelivery: FC<{ deliveryStates: any, setDeliveryStates: any }> = ({ deliveryStates, setDeliveryStates }) => {
    const [name, setName] = useState("");
    const [bin, setBIN] = useState("")
    const [paymentDates, setPaymentDates] = useState([])
    const [deliveryDate, setDeliveryDate] = useState("")
    const [deliveryCost, setDeliveryCost] = useState(0)
    const [contractName, setContractName] = useState("");
    const [signingDate, setSigningDate] = useState(0);
    const [endingDate, setEndingDate] = useState(0);
    const [contractCost, setContractConst] = useState("");
    const [loading, setLoading] = useState(true)
    const [deliveryId, setDeliveryId] = useState(0)

    const [invoicesFiles, setInvoicesFiles] = useState([])
    const [factorFiles, setFactorFiles] = useState([])
    const [acceptingFiles, setAcceptingFiles] = useState([])
    const [othersFiles, setOthersFiles] = useState([])

    const [existingInvoicesFiles, setExistingInvoicesFiles] = useState<Array<any>>([])
    const [existingFactorFiles, setExistingFactorFiles] = useState<Array<any>>([])
    const [existingAcceptingFiles, setExistingAcceptingFiles] = useState<Array<any>>([])
    const [existingOthersFiles, setExistingOthersFiles] = useState<Array<any>>([])

    const [isModalWarmingOpened, setModalWarmingOpen] = useState(false)
    const [uploadingFiles, setUploadingFiles] = useState(false)

    const handleCancel = () => {
        if (invoicesFiles.length != 0 || factorFiles.length != 0 || acceptingFiles.length != 0 || othersFiles.length != 0)
            setModalWarmingOpen(true)
        else
            closeModal()
    }

    const closeModal = () => {
        setName("")
        setBIN("")
        setModalWarmingOpen(false)
        setDeliveryStates({ state: false, id: 0 })
    }

    const saveChanges = async () => {
        setUploadingFiles(true)
        const response: any = await addMoreFiles(deliveryId, invoicesFiles, factorFiles, acceptingFiles, othersFiles)
        setUploadingFiles(false)
        closeModal()
    }

    const renderInfo = async () => {
        setLoading(true)
        const response: any = await getDeliveryInfo(deliveryStates.id);
        if (!response.data)
            return
        let temp = Array<any>()
        response.data.files.map((elem: any) => {
            if (elem.split("/").at(-1).split("_")[0] == "Накладная")
                temp.push(elem)
        })
        setExistingInvoicesFiles(temp)
        temp = Array<any>()
        response.data.files.map((elem: any) => {
            console.log(elem.split("/").at(-1).split("_")[0])
            if (elem.split("/").at(-1).split("_")[0] == "Счёт Фактура")
                temp.push(elem)
        })
        setExistingFactorFiles(temp)
        temp = Array<any>()
        response.data.files.map((elem: any) => {
            console.log(elem.split("/").at(-1).split("_")[0])
            if (elem.split("/").at(-1).split("_")[0] == "Акт приёма")
                temp.push(elem)
        })
        setExistingAcceptingFiles(temp)
        temp = Array<any>()
        response.data.files.map((elem: any) => {
            console.log(elem.split("/").at(-1).split("_")[0])
            if (elem.split("/").at(-1).split("_")[0] == "Прочее")
                temp.push(elem)
        })
        setExistingOthersFiles(temp)
        setPaymentDates(response.data.payment_at.split(","))
        setDeliveryDate(response.data.deliveried_at)
        setDeliveryCost(response.data.delivery_cost)
        setName(response.data.client_name)
        setBIN(response.data.client_bin)
        setContractConst(response.data.contract_cost)
        setContractName(response.data.contract_name)
        setSigningDate(response.data.signing_date)
        setEndingDate(response.data.ending_date)
        setDeliveryId(response.data.delivery_id)
        setLoading(false)
    }

    useEffect(() => {
        renderInfo();
    }, [deliveryStates.id])

    const formatNumber = (value: string) => {
        return value.split("").reverse().join("").replace(/(.{3})/g, "$1 ").split("").reverse().join("").trim()
    }

    if (!deliveryStates.state)
        return <></>

    return (
        <div className="bg-black/40 w-screen h-screen fixed top-0 left-0 flex flex-col justify-start sm:items-center pb-16 pt-8 sm:pt-16 z-40 overflow-y-auto">
            <div className='w-full sm:max-w-3xl px-4 sm:px-6 lg:px-8 pb-8'>
                <div className="bg-white p-4 rounded-xl flex flex-col">
                    <div className="text-2xl font-medium col-span-4 flex flex-row items-start justify-between">
                        <div>Информация о поставке</div>
                        <div>
                            <XIcon onClick={handleCancel} className="h-6 text-black/60 hover:text-black duration-300 cursor-pointer"></XIcon>
                        </div>
                    </div>
                    {/* <div className="mt-2 text-black/60 col-span-4">Данный договор будет отправлено оппоненту для подтверждения</div> */}
                    <div className="mt-4 col-span-4 relative">
                        <label className="block font-medium leading-6 text-gray-900 mt-4 relative">Наименование покупателя</label>
                        {
                            loading ? <div className="h-6 w-1/3 mt-1 bg-slate-100 rounded"></div> : <div className="mt-1">{name}</div>
                        }
                    </div>
                    <div className="">
                        <label className="block font-medium leading-6 text-gray-900 mt-2">БИН</label>
                        {
                            loading ? <div className="h-6 w-1/3 mt-1 bg-slate-100 rounded"></div> : <div className="mt-1">{bin.replace(/(\d{3})(\d{3})(\d{3})(\d{3})/, "$1-$2-$3-$4")}</div>
                        }
                    </div>
                    <div className="mt-4 py-2 border-t col-span-4 relative">
                        <label className="block font-medium leading-6 text-gray-900 mt-2 relative">Наименование контракта</label>
                        {
                            loading ? <div className="h-6 w-1/3 mt-1 bg-slate-100 rounded"></div> : <div className="mt-1">{contractName}</div>
                        }
                    </div>
                    <div className="">
                        <label className="block font-medium leading-6 text-gray-900 mt-2">Сумма контракта</label>
                        {
                            loading ? <div className="h-6 w-1/3 mt-1 bg-slate-100 rounded"></div> : <div className="mt-1">{formatNumber(contractCost.toString())}</div>
                        }
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <div className="">
                            <label className="block font-medium leading-6 text-gray-900 mt-2">Дата заключение</label>
                            {
                                loading ? <div className="h-6 w-1/3 mt-1 bg-slate-100 rounded"></div> : <div className="mt-1">{ShortTimestampToString(signingDate)}</div>
                            }
                        </div>
                        <div className="">
                            <label className="block font-medium leading-6 text-gray-900 mt-2">Дата окончания</label>
                            {
                                loading ? <div className="h-6 w-1/3 mt-1 bg-slate-100 rounded"></div> : <div className="mt-1">{ShortTimestampToString(endingDate)}</div>
                            }
                        </div>
                    </div>
                    <div className="mt-4 py-2 border-t">
                        <label className="block font-medium leading-6 text-gray-900 mt-2">Дата поставки</label>
                        {
                            loading ? <div className="h-6 w-1/3 mt-1 bg-slate-100 rounded"></div> :
                                <div className="mt-1">
                                    {ShortTimestampToString(Number(deliveryDate))}
                                </div>
                        }

                    </div>
                    <div className="mt-4 py-2 border-t">
                        <label className="block font-medium leading-6 text-gray-900 mt-2">Даты платежа</label>
                        <div className="flex flex-row flex-wrap gap-2 mt-1">
                            {
                                loading ? <div className="h-8 w-2/3 mt-1 bg-slate-100 rounded"></div> :
                                    paymentDates.length != 0 ?
                                        paymentDates.sort().map((elem) => {
                                            return (
                                                <div className="py-1 px-2 bg-slate-100 rounded">
                                                    {ShortTimestampToString(elem)}
                                                </div>
                                            )
                                        })
                                        : <div className="h-6"></div>
                            }
                        </div>
                    </div>
                    <div className="col-span-2">
                        <label className="block font-medium leading-6 text-gray-900 mt-2">Сумма платежа</label>
                        {
                            loading ? <div className="h-6 w-1/3 mt-1 bg-slate-100 rounded"></div> :
                                <div className="mt-1">
                                    {formatNumber(deliveryCost.toString())}
                                </div>
                        }

                        {/* {
                            !loading && paymentDates.length > 1 ?
                                <div className="text-sm text-black/60 mt-1">
                                    Размер одного платежа: {(deliveryCost / paymentDates.length).toFixed(2)}
                                </div>
                                : <></>
                        } */}
                    </div>
                    <div className="mt-5 border-t pt-4 flex flex-col gap-3 pb-4">
                        <div className="flex flex-row justify-between items-end">
                            <div className="text-xl">Файлы</div>
                            {existingAcceptingFiles.length + existingFactorFiles.length + existingInvoicesFiles.length + existingOthersFiles.length != 0 ?
                                <div>
                                    <a className="text-blue-600" href={`https://elabfactor.com/api/deliveries/downloadDelivery.php?token=${localStorage.getItem('access_token')}&delivery_id=${deliveryId}`}>Скачать всё</a>
                                </div> : <></>
                            }
                        </div>
                        <MultipleDocumentInLineWithAddingNew name="Накладная" doc_info="не помню" files={invoicesFiles} setFiles={setInvoicesFiles} exsitingFiles={existingInvoicesFiles}></MultipleDocumentInLineWithAddingNew>
                        <MultipleDocumentInLineWithAddingNew name="Счёт фактура" doc_info="не помню" files={factorFiles} setFiles={setFactorFiles} exsitingFiles={existingFactorFiles}></MultipleDocumentInLineWithAddingNew>
                        <MultipleDocumentInLineWithAddingNew name="Акт ПП/ВР" doc_info="не помню" files={acceptingFiles} setFiles={setAcceptingFiles} exsitingFiles={existingAcceptingFiles}></MultipleDocumentInLineWithAddingNew>
                        <MultipleDocumentInLineWithAddingNew name="Прочие файлы" doc_info="не помню" files={othersFiles} setFiles={setOthersFiles} exsitingFiles={existingOthersFiles}></MultipleDocumentInLineWithAddingNew>
                    </div>
                    {
                        invoicesFiles.length != 0 || factorFiles.length != 0 || acceptingFiles.length != 0 || othersFiles.length != 0 ?
                            <div className="flex flex-row justify-end pt-2">
                                <button onClick={saveChanges} className="bg-blue-600 text-white py-2 px-4 rounded-md">Сохранить изменения</button>
                            </div> :
                            <></>
                    }

                    {/* <div className="mt-5 border-t pt-4">
                        <label className="block font-medium leading-6 text-gray-900 pb-1">Документы</label>
                        <a className="text-blue-600" href={`http://45.142.213.77:4040/api/deliveries/downloadDelivery.php?token=${localStorage.getItem('access_token')}&delivery_id=${deliveryId}`}>Скачать</a>
                    </div> */}

                    {/* <div className="flex flex-row mt-10 gap-x-4 col-span-4">
                        <button disabled={uploadingFiles} onClick={handleCancel} className="py-2 bg-gray-200 w-2/5 font-medium rounded">Отмена</button>
                        <button disabled={uploadingFiles} onClick={handleSendButton} className="py-2 bg-[#12436E] text-white font-medium w-3/5 rounded">Отправить</button>
                    </div> */}
                </div>
                {
                    uploadingFiles ?
                        <div className="fixed w-full h-full z-[99] flex flex-col top-0 left-0 justify-center items-center bg-black/20">
                            <div className="w-16 h-16">
                                <ReactLoading type="spin" color="#fff"></ReactLoading>
                            </div>
                        </div>
                        : <></>
                }
            </div>
            <WamingSaveModal saveButton={saveChanges} setOpen={closeModal} active={isModalWarmingOpened}></WamingSaveModal>
        </div>
    )
}

const WamingSaveModal: FC<{ saveButton: any, setOpen: any, active: boolean }> = ({ saveButton, setOpen, active }) => {

    if (!active)
        return <></>

    return (
        <div className="bg-black/40 fixed w-screen h-screen top-0 left-0 flex flex-row justify-center items-center">
            <div className="bg-white rounded-xl max-w-2xl p-4">
                <div className="text-xl font-medium mt-1">Сохранить изменения?</div>
                <div>Вы не сохранили изменения, хотите ли вы сохранить их?</div>
                <div className="flex flex-row pt-8 gap-2 justify-end">
                    <button onClick={setOpen} className="py-2 px-4 bg-slate-200 rounded-md text-lg">Отмена</button>
                    <button onClick={saveButton} className="py-2 px-4 bg-blue-600 rounded-md text-white text-lg">Сохранить</button>
                </div>
            </div>
        </div>
    )
}

export default OpenDelivery;