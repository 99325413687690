import { FC, useState, useEffect } from 'react'
import { getContracts } from '../../libs/contractsController'
import { ShortTimestampToString } from '../../utils/dates'
import ReactLoading from 'react-loading';

const ContractSelection: FC<{ active: any, setOpen: any, setContractId: any, client_id: any }> = ({ active, setOpen, setContractId, client_id }) => {
    const [contracts, setContracts] = useState<any>(null)

    const [loading, setLoading] = useState(true)

    const renderContracts = async () => {
        setLoading(true)
        const contracts = await getContracts(client_id)
        setLoading(false)
        setContracts(contracts)
    }

    useEffect(() => {
        if (active)
            renderContracts()
    }, [client_id, active])

    if (!active) return <></>
    return (
        <div onClick={() => setOpen(false)} className="bg-black/40 w-screen h-screen fixed top-0 left-0 flex flex-col justify-start sm:items-center pb-8 pt-8 sm:pt-16 z-40 overflow-y-auto">
            <div className="bg-white rounded-xl max-w-2xl w-full mx-auto">
                <div className="flex flex-col divide-y rounded-lg overflow-hidden">
                    <div className="bg-slate-100 font-medium rounded-t-lg px-4 py-2 grid grid-cols-2 lg:grid-cols-4 text-left">
                        <div>Наименование</div>
                        <div className="hidden lg:block">Дата заключения</div>
                        <div>Сумма</div>
                        <div className="hidden lg:block">Срок окончания</div>
                    </div>
                    <div>
                        {
                            loading ?
                                [1, 2, 3].map(() =>
                                    <div className="bg-white py-2 px-4 text-left relative duration-300 cursor-pointer">
                                        <div className='w-full bg-slate-100 py-1 rounded text-transparent' >{"Test"}</div>
                                    </div>
                                )
                                :
                                !contracts || contracts.length == 0 ? <div className="py-3 px-4 text-center">У вас пока нет договоров с данным клиентом</div>
                                    : contracts.map((element: any) => {
                                        return (
                                            <div onClick={() => { setContractId(element); setOpen(false) }} key={`Contract ${element.contract_name}`} className="bg-white hover:bg-slate-100 py-3 px-4 grid grid-cols-2 lg:grid-cols-4 text-left relative duration-300 cursor-pointer">
                                                <div>{element.contract_name}</div>
                                                <div className="hidden lg:block">{ShortTimestampToString(element.signing_date)}</div>
                                                <div>{element.cost}</div>
                                                <div className="hidden lg:block">{ShortTimestampToString(element.ending_date)}</div>
                                            </div>
                                        )
                                    })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContractSelection