import { FC, useRef, useEffect, useState } from "react";
import { PaperClipIcon, XIcon, PlusIcon } from "@heroicons/react/outline";

const MultipleDocumentInLine: FC<{ doc_info: any, name: string, files: Array<any>, setFiles: any }> = ({ doc_info, name, files, setFiles }) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const storageRef = useRef<HTMLInputElement | null>(null);

    const handleUploadClick = () => {
        inputRef.current?.click();
    }

    const [formData, setFormData] = useState({files: files})


    const handleUploadFile = (elem: any) => {
        console.log(elem.target.files);
        let temp = Array<any>()
        for (var i = 0; i < elem.target.files.length; i++) {
            temp.push(elem.target.files[i])
        }
        setFormData({files: [...formData.files, ...temp]})
    }
    
    const removeFile = (id: number) => {
        console.log(id)
        let temp = formData.files
        temp.splice(id, 1)
        setFormData({files: temp})
    }

    useEffect(() => {
        setFiles(formData.files)
    }, [formData])

    return (
        <div>
            <div className="flex flex-row text-base items-end justify-start">
                <div className="font-medium">{name}</div>
                <button className="ml-3 text-blue-600" onClick={handleUploadClick}>
                    {"Добавить"}
                {/* <PlusIcon className="h-4 w-4"></PlusIcon> */}
                </button>
            </div>
            <input type="file" multiple ref={inputRef} className="hidden absolute" onChange={e => handleUploadFile(e)} />
            <input type="file" multiple ref={storageRef} className="hidden absolute" />
            <div className="flex flex-col mt-1">

                {
                    formData.files.map((elem: any, index) => {
                        return (
                            <div key={`File ${elem.name} ${index-1}`} className="bg-white border rounded text-base pl-2 pr-4 py-1 mt-1 flex flex-row justify-between items-center">
                                <div className="flex flex-row items-center font-medium">
                                    <PaperClipIcon className="h-5 pr-2 text-blue-500"></PaperClipIcon>
                                    {elem.name}
                                </div>
                                <div>
                                    <XIcon onClick={() => {removeFile(index)}} className="h-5 text-black/60 hover:text-black cursor-pointer"></XIcon>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default MultipleDocumentInLine