import axios from 'axios';
const SERVER_URL = "https://elabfactor.com";

const checkAndOut = async () => {
    if (! await checkauth()) {
        await logout()
        window.open("/app/auth", "_self")
    }
}

const signin = async (data: any) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/auth/signin.php`;

    try {
        let response = await axios.post(LOGIN_ENDPOINT, data, {
            "headers": {
                "Content-Type": "multipart/form-data"
            }
        });

        if (response.data.error === 0 && response.data.jwt && response.data.expireAt) {
            let jwt = response.data.jwt;
            let expire_at = response.data.expireAt;

            localStorage.setItem("access_token", jwt);
            localStorage.setItem("expire_at", expire_at);
        }

        return response

    } catch (e) {
        console.log(e);
    }
}

const requestCodeForReset = async (data: any) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/profile/requestCodeToReset.php`;
    try {
        let response = await axios.post(LOGIN_ENDPOINT, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });

        return response
    } catch (e) {
        console.log(e);
    }
}

const sendCodeForReset = async (data: any) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/profile/passwordReset.php`;
    try {
        let response = await axios.post(LOGIN_ENDPOINT, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });

        return response
    } catch (e) {
        console.log(e);
    }
}

const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("expire_at");
}

const checkauth = async () => {
    const SIGNUP_ENDPOINT = `${SERVER_URL}/api/auth/protected.php`;

    try {
        let response = await axios.get(SIGNUP_ENDPOINT, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
                "Access-Control-Allow-Headers": "Authorization"
            },
        });

        return true;

    } catch (e) {
        console.log(e);
        return false;
    }
}

const postRegistrationForm = async (
    comp_name: string,
    comp_bin: string,
    manager_fullname: string,
    address: string,
    email: string,
    contact_position: string,
    contact_fullname: string,
    contact_phone: string,
    contact_email: string) => {

    const ENDPOINT = `${SERVER_URL}/api/forms/postRegistrationForm.php`

    const data = {
        'comp_name': comp_name,
        'comp_bin': comp_bin,
        'manager_fullname': manager_fullname,
        'address': address,
        'email': email,
        'contact_position': contact_position,
        'contact_fullname': contact_fullname,
        'contact_phone': contact_phone,
        'contact_email': contact_email
    }

    try {
        let response = await axios.post(
            ENDPOINT,
            data,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
        );

        return response.data
    } catch (e) {
        console.log(e)
    }
}

const getCode = async (email: string) => {
    const SIGNUP_ENDPOINT = `${SERVER_URL}/api/forms/getCode.php?email=${email}`;

    try {
        let response = await axios.get(SIGNUP_ENDPOINT);
        return response.data;
    } catch (e) {
        return false;
    }
}


export { checkAndOut, checkauth, getCode, logout, postRegistrationForm, requestCodeForReset, sendCodeForReset, signin };
