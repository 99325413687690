import axios from 'axios';
const SERVER_URL = "https://elabfactor.com";

const uploadFile = async (file: any, name: string) => {
    const ENDPOINT = `${SERVER_URL}/api/files/uploadFile.php?name=${name}`;
    const formData = new FormData();

    console.log("Upload file front")

    formData.append('document', file)

    const response = await axios.post(ENDPOINT, formData, {
        headers: {
            'content-type': 'multipart/form-data',
            "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        }
    })

    return response;
}


const getDocuments = async () => {
    const ENDPOINT = `${SERVER_URL}/api/files/getAllDocs.php`;

    const response = await axios.get(ENDPOINT, {
        headers: {
            'content-type': 'multipart/form-data',
            "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        }
    });

    return response
}

const removeFile = async (data: any) => {
    const ENDPOINT = `${SERVER_URL}/api/files/removeFile.php`;
    try {
        let response = await axios.post(ENDPOINT, data, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },

        });

    } catch (e) {
        console.log(e);
    }
}

export { uploadFile, getDocuments, removeFile } 