import { DownloadIcon, PlusIcon, XIcon } from "@heroicons/react/outline"
import { FC, useEffect, useState } from "react"
import { getClient } from "../../../../libs/clientController"
import { getContracts } from "../../../../libs/contractsController"
import { ShortTimestampToString } from "../../../../utils/dates"
import phoneFormat from "../../../../utils/phoneFormater"
import AddContract from "../addContract/AddContract"

const ViewClient: FC<{ viewRef: any, setViewRef: any }> = ({ viewRef, setViewRef }) => {
    const [addContractModal, setAddContractModal] = useState(false)
    const [contracts, setContracts] = useState<any>(null)
    const [isLoading, setLoading] = useState(true)
    const [client, setClient] = useState<any>({
        client_id: "",
        user_id: "",
        client_name: "",
        client_bin: "",
        contact_fullname: "",
        contact_position: "",
        contact_phone: "",
        contact_email: ""
    })

    const renderClient = async () => {
        setLoading(true)
        setClient(await getClient(viewRef['id']));
        setLoading(false)
    }

    const renderContracts = async () => {
        const contracts = await getContracts(viewRef['id'])
        setContracts(contracts)
    }

    useEffect(() => {
        renderContracts()
    }, [addContractModal])

    useEffect(() => {
        if (viewRef["id"] != 0) {
            setContracts([])
            renderClient();
            renderContracts()
        }

    }, [viewRef])

    if (!viewRef.state) return <></>

    return (
        <div className="bg-black/40 w-screen h-screen fixed top-0 left-0 flex flex-col justify-start sm:items-center pt-8 sm:pt-16 z-40 overflow-y-auto">
            <div className='w-full sm:max-w-3xl px-4 sm:px-6 lg:px-8 pb-8'>
                <div className="bg-white p-6 rounded-xl flex flex-col pb-8">
                    <div className="text-2xl font-medium col-span-4 flex flex-row items-start justify-between">
                        <div className="flex flex-col">
                            <div>Информация о контрагенте</div>
                            <div className="mt-2 text-black/60 text-base font-normal">Здесь отображается вся информация о контракенте и заключенные с ним договоры</div>
                        </div>
                        <div>
                            <XIcon onClick={() => { setViewRef({ state: false, id: 0 }) }} className="h-6 text-black/60 hover:text-black duration-300 cursor-pointer"></XIcon>
                        </div>
                    </div>
                    {/* <div className="mt-2 text-black/60 col-span-4">Данный договор будет отправлено оппоненту для подтверждения</div> */}
                    <div className="text-2xl font-medium mt-6">Данные</div>
                    <div className="flex flex-col sm:flex-row mt-3 sm:space-x-4">
                        <div className="sm:w-1/2">
                            <label className="block font-medium leading-6 text-gray-800">Название</label>
                            <div className="mt-1 bg-slate-100 py-2 px-4 rounded">{!isLoading ? client.client_name : <div className="w-2/3 bg-slate-200 rounded text-transparent">temp</div>}</div>
                        </div>
                        <div className="sm:w-1/3">
                            <label className="block font-medium leading-6 text-gray-800">БИН</label>
                            <div className="mt-1 bg-slate-100 py-2 px-4 rounded">{!isLoading ? client.client_bin.replace(/(\d{3})(\d{3})(\d{3})(\d{3})/, "$1-$2-$3-$4") : <div className="w-2/3 bg-slate-200 rounded text-transparent">temp</div>}</div>
                        </div>
                    </div>

                    <div className="text-2xl font-medium  mt-6 pt-4 border-t">Контактное лицо</div>
                    <div className="flex flex-col sm:flex-row mt-3 gap-4">
                        <div className="sm:w-3/5">
                            <label className="block font-medium leading-6 text-gray-800">ФИО</label>
                            <div className="mt-1 bg-slate-100 py-2 px-4 rounded">{!isLoading ? client.contact_fullname : <div className="w-2/3 bg-slate-200 rounded text-transparent">temp</div>}</div>
                        </div>
                        <div className="sm:w-2/5">
                            <label className="block font-medium leading-6 text-gray-800">Должность</label>
                            <div className="mt-1 bg-slate-100 py-2 px-4 rounded">{!isLoading ? client.contact_position : <div className="w-2/3 bg-slate-200 rounded text-transparent">temp</div>}</div>
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row mt-4 gap-4">
                        <div className="sm:w-1/3">
                            <label className="block font-medium leading-6 text-gray-800">Контактный телефон</label>
                            <div className="mt-1 bg-slate-100 py-2 px-4 rounded">{!isLoading ? phoneFormat(client.contact_phone) : <div className="w-2/3 bg-slate-200 rounded text-transparent">temp</div>}</div>
                        </div>
                        <div className="sm:w-1/2">
                            <label className="block font-medium leading-6 text-gray-800">Почта</label>
                            <div className="mt-1 bg-slate-100 py-2 px-4 rounded">{!isLoading ? client.contact_email : <div className="w-2/3 bg-slate-200 rounded text-transparent">temp</div>}</div>
                        </div>
                    </div>

                    <div className="text-2xl font-medium mt-6 pt-4 border-t flex flex-row justify-start items-end">
                        <div>Договоры</div>
                        <button onClick={() => { setAddContractModal(true) }} className="text-center text-base bg-slate-200 py-2 duration-300 hover:bg-slate-300 rounded text-black px-2 ml-3">
                            <PlusIcon className="h-4 w-4"></PlusIcon>
                        </button>
                        {/* <div className=" bg-blue-600 rounded p-1 ml-2"><PlusIcon className="h-4 w-4 text-white"></PlusIcon></div> */}
                    </div>
                    <div className="flex flex-col mt-4 divide-y border rounded-lg">
                        <div className="bg-slate-100 font-medium rounded-t-lg px-4 py-2 grid grid-cols-2 sm:grid-cols-4 text-left pr-12 gap-1">
                            <div>Наименование</div>
                            <div className="hidden sm:block">Дата заключения</div>
                            <div className="hidden sm:block">Сумма</div>
                            <div>Срок окончания</div>
                        </div>
                        {
                            !contracts || contracts.length == 0 ? <div className="py-3 px-4 text-center">{isLoading ? <div className="w-full bg-slate-100 rounded text-transparent">test</div> : <p>У вас пока нет договоров <span className='whitespace-nowrap'>с данным клиентом</span></p>}</div>
                                : contracts.map((element: any) => {
                                    return (
                                        <div className="flex flex-row pr-2 items-center">
                                            <div key={`Contract ${element.contract_name}`} className="bg-slate-white py-3 px-4 grid grid-cols-2 sm:grid-cols-4 gap-2 text-left flex-auto items-center">
                                                <div>{element.contract_name}</div>
                                                <div className="hidden sm:block">{ShortTimestampToString(element.signing_date)}</div>
                                                <div className="hidden sm:block">{element.cost}</div>
                                                <div>{ShortTimestampToString(element.ending_date)}</div>
                                            </div>
                                            <a href={`https://elabfactor.com/api/contracts/downloadContract.php?token=${localStorage.getItem('access_token')}&contract_id=${element.contract_id}`}>
                                                <DownloadIcon className="h-6 w-6 text-black/80" />
                                            </a>
                                        </div>
                                    )
                                })
                        }

                    </div>

                    {/* <div className="flex flex-row mt-10 gap-x-4 col-span-4">
                        <button disabled={uploadingFiles} onClick={handleCancel} className="py-2 bg-gray-200 w-2/5 font-medium rounded">Отмена</button>
                        <button disabled={uploadingFiles} onClick={handleSendButton} className="py-2 bg-[#12436E] text-white font-medium w-3/5 rounded">Отправить</button>
                    </div> */}
                </div>
            </div>
            <AddContract active={addContractModal} setOpen={setAddContractModal} client_id={client.client_id}></AddContract>
        </div>
    )
}

export default ViewClient