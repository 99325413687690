import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { checkauth, logout, requestCodeForReset, sendCodeForReset } from "../../libs/authContoller";

const PasswordReset = () => {
    const [email, setEmail] = useState("");
    const [codeId, setCodeId] = useState(0)
    const [loading, setLoading] = useState(true)
    const [localLoading, setLocalLoading] = useState(false)

    const [verificationEmail, setVerificationEmail] = useState(false)

    const [digitsCode, setDigidsCode] = useState('')
    const [codeError, setCodeError] = useState(false)
    const [emailError, setEmailError] = useState(false)

    document.title = "Сброс пароля"

    const check = async () => {
        setLoading(true)
        if (localStorage.getItem('access_token') != null)
            if (await checkauth()) {
                window.open("/app/profile", "_self")
            } else {
                logout()
                setLoading(false)
            }
        else (
            setLoading(false)
        )
    }

    useEffect(() => {
        check()
    }, [])

    const handleEnterEmail = async () => {
        setLocalLoading(true)
        const response: any = await requestCodeForReset({ "email": email })
        if (response.data.error != "User wasn't found") {
            setCodeId(response.data.code_id)
            setVerificationEmail(true)
        } else {
            setEmailError(true)
        }
        setLocalLoading(false)
    }

    const sendCode = async (e: string) => {
        setLocalLoading(true)
        const response: any = await sendCodeForReset({ "code": e, "code_id": codeId, "email": email })
        if (response.data.errorCode == 0) {
            // logout()
            window.open("/app/auth", "_self")
            // setOpen(false)
        } else {
            setCodeError(true)
        }
        setLocalLoading(false)
    }

    const enterCode = (e: string) => {
        const code = e.replace("-", "")
        setCodeError(false)
        if (code.length <= 6)
            setDigidsCode(code)
        if (code.length == 6) {
            sendCode(code)
        }
    }

    const formatCode = (value: string) => {
        return value.replace(/(\d{3})/, "$1-")
    }

    if (loading)
        return (
            <div className="absolute w-full h-full z-[99] flex flex-col top-0 left-0 justify-center items-center bg-gradient-to-br from-[#355a79] to-[#1C4B74]">
                <div className="w-16 h-16">
                    <ReactLoading type="spin" color="#fff"></ReactLoading>
                </div>
            </div>)

    if (verificationEmail)
        return (
            <div className="h-screen flex flex-row justify-center items-center px-4 sm:px-6 lg:px-8 bg-gradient-to-br from-[#355a79] to-[#1C4B74]">
                <div className="sm:max-w-lg bg-white rounded-lg w-full flex flex-col p-8">
                    <div className="h-32 aspect-1 self-center">
                        <img src="/logo.svg" alt="" className="w-full h-full" />
                    </div>
                    <div className="text-center text-3xl mt-4 font-medium text-[#11436e]">e-Lab Factor</div>
                    <div className="text-center text-lg text-[#11436e]/75">Факторинговая компания</div>
                    <div className="text-center mt-8">
                        Введите код высланный на почту {email}
                    </div>
                    <div className="mt-8">
                        {/* <div className="font-medium">Почта</div> */}
                        <input disabled={localLoading} value={formatCode(digitsCode)} onChange={e => { enterCode(e.target.value); setCodeError(false) }} placeholder="******" className={`mt-2 block flex-1 border-1 ${codeError ? "border-red-600 focus:border-red-600" : "border-transparent focus:border-transparent"} py-2 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6 bg-gray-100 w-full rounded`} type="text" />
                    </div>
                </div>
            </div>
        )

    return (
        <div className="h-screen flex flex-row justify-center items-center px-4 sm:px-6 lg:px-8 bg-gradient-to-br from-[#355a79] to-[#1C4B74]">
            <div className="sm:max-w-lg bg-white rounded-lg w-full flex flex-col p-8">
                <div className="h-32 aspect-1 self-center">
                    <img src="/logo.svg" alt="" className="w-full h-full" />
                </div>
                <div className="text-center text-3xl mt-4 font-medium text-[#11436e]">e-Lab Factor</div>
                <div className="text-center text-lg text-[#11436e]/75">Факторинговая компания</div>
                <div className="text-center mt-8">
                    Введите почту, на которую был зарегестрирован пользователь
                </div>
                <div className="mt-8">
                    {/* <div className="font-medium">Почта</div> */}
                    <input disabled={localLoading} placeholder="Почта" value={email} onChange={e => { setEmail(e.target.value); setEmailError(false)  }} className={`mt-2 block flex-1 border-1 ${emailError ? "border-red-600 focus:border-red-600" : "border-transparent focus:border-transparent"} py-2 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6 bg-gray-100 w-full rounded`} type="text" />
                </div>
                <div className="mt-4">
                    <button disabled={localLoading} onClick={handleEnterEmail} className="w-full bg-gradient-to-br from-[#355a79] to-[#1C4B74] text-white rounded py-2 enabled:hover:opacity-[85%] duration-300">Получить код</button>
                </div>
            </div>
        </div>
    )
}



export default PasswordReset;