import axios from 'axios';
const SERVER_URL = "https://elabfactor.com";

const getAllClients= async (value: string) => {
    const ENDPOINT = `${SERVER_URL}/api/clients/getMyClientsByPage.php?search=${value}`;

    try {
        let response = await axios.get(ENDPOINT, {
            headers: {
                'content-type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },
        })

        return response.data
    } catch (e: any) {
        console.log(e.response!.data.message);
        if (e.response.data.message == "Access denied.")
            window.open("/app/auth", "_self")
    }
}

const getClientsWithPage = async (value: string, currentPage: number) => {
    const ENDPOINT = `${SERVER_URL}/api/clients/getMyClientsByPage.php?search=${value}&page=${currentPage}`;

    try {
        let response = await axios.get(ENDPOINT, {
            headers: {
                'content-type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },
        })

        return response.data
    } catch (e: any) {
        console.log(e.response!.data.message);
        if (e.response.data.message == "Access denied.")
            window.open("/app/auth", "_self")
    }
}

const getClientsCount = async (value: string) => {
    const ENDPOINT = `${SERVER_URL}/api/clients/getCountMyClients.php?search=${value}`;

    try {
        let response = await axios.get(ENDPOINT, {
            headers: {
                'content-type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },
        })

        return response
    } catch (e: any) {
        console.log(e.response!.data.message);
        if (e.response.data.message == "Access denied.")
            window.open("/app/auth", "_self")
    }
}

const getClient = async (value: string) => {
    const ENDPOINT = `${SERVER_URL}/api/clients/getClientInfoById.php?id=${value}`;

    try {
        let response = await axios.get(ENDPOINT, {
            headers: {
                'content-type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },
        })

        return response.data
    } catch (e: any) {
        console.log(e.response!.data.message);
        if (e.response.data.message == "Access denied.")
            window.open("/app/auth", "_self")
    }
}

const addClient = async (data: any) => {
    const ENDPOINT = `${SERVER_URL}/api/clients/addClient.php`

    try {
        let response = await axios.post(ENDPOINT, data, {
            headers: {
                "Content-Type" : "multipart/form-data",
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },
        });

        return response.data
    } catch (e: any) {
        console.log(e.response!.data.message);
        if (e.response.data.message == "Access denied.")
            window.open("/app/auth", "_self")
    }
}


export { addClient, getClientsWithPage, getAllClients, getClient, getClientsCount } 