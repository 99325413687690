import { createContext, useState } from "react";

interface DliveryFormContext {
    data: {},
    setDelivery: (date: {
        name: string,
        bin: string,
        client_id: string,
        invoice_files: [],
        factor_files: [],
        act_files: [],
        others_files: [],
        payment_dates: number[],
        delivery_date: number,
        delivery_cost: number,
        contract: any,
        comment: string
    }) => void;
}

export const DeliveryContext = createContext<DliveryFormContext>({
    data: {},
    setDelivery: () => { },
});

