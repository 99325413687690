import axios from 'axios';
const SERVER_URL = "https://elabfactor.com";

const addDelivery = async (contractId: number, invoiceFiles: any, factorFiles: any, actFiles: any, otherFiles: any, client_id: any, payment_dates: any, delivery_date: any, cost: any, comment: string) => {
    const ENDPOINT = `${SERVER_URL}/api/deliveries/addDelivery.php`

    const formData = new FormData();
    for (var i = 0; i < invoiceFiles.length; i++ ){
        let file = invoiceFiles[i];
        formData.append('invoiceFiles[' + i + ']', file);
    }
    for (var i = 0; i < factorFiles.length; i++ ){
        let file = factorFiles[i];
        formData.append('factorFiles[' + i + ']', file);
    }
    for (var i = 0; i < actFiles.length; i++ ){
        let file = actFiles[i];
        formData.append('actFiles[' + i + ']', file);
    }
    for (var i = 0; i < otherFiles.length; i++ ){
        let file = otherFiles[i];
        formData.append('otherFiles[' + i + ']', file);
    }
    formData.append('client_id', client_id.toString());
    formData.append('contract_id', contractId.toString());
    formData.append('payment_at', payment_dates.join(","));
    formData.append('deliveried_at', delivery_date.toString());
    formData.append('cost', cost.toString());
    formData.append('comment', comment.toString());

    try {
        let response = await axios.post(ENDPOINT, formData, {
            headers: {
                'content-type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },
        });

        return response.data
    } catch (e: any) {
        console.log(e.response!.data.message);
        if (e.response.data.message == "Access denied.")
            window.open("/app/auth", "_self")
    }
}

const getDeliveries = async (value: string, currentPage: number) => {
    const ENDPOINT = `${SERVER_URL}/api/deliveries/getMyDeliveries.php?search=${value}&page=${currentPage}`;

    try {
        let response = await axios.get(ENDPOINT, {
            headers: {
                'content-type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },
        })

        return response
    } catch (e: any) {
        console.log(e.response!.data.message);
        if (e.response.data.message == "Access denied.")
            window.open("/app/auth", "_self")
    }
}


const getDeliveriesCount = async (value: string) => {
    const ENDPOINT = `${SERVER_URL}/api/deliveries/getCountMyDeliveries.php?search=${value}`;

    try {
        let response = await axios.get(ENDPOINT, {
            headers: {
                'content-type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },
        })

        return response
    } catch (e: any) {
        console.log(e.response!.data.message);
        if (e.response.data.message == "Access denied.")
            window.open("/app/auth", "_self")
    }
}

const getDeliveryInfo = async (id: any) => {
    const ENDPOINT = `${SERVER_URL}/api/deliveries/getDeliveryInfo.php?delivery_id=${id}`;

    try {
        let response = await axios.get(ENDPOINT, {
            headers: {
                'content-type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },
        })

        return response
    } catch (e: any) {
        console.log(e.response!.data.message);
        if (e.response.data.message == "Access denied.")
            window.open("/app/auth", "_self")
    }
}

const addMoreFiles = async (deliveryId: number, invoiceFiles: any, factorFiles: any, actFiles: any, otherFiles: any) => {
    const ENDPOINT = `${SERVER_URL}/api/deliveries/addMoreFiles.php`

    const formData = new FormData();
    for (var i = 0; i < invoiceFiles.length; i++ ){
        let file = invoiceFiles[i];
        formData.append('invoiceFiles[' + i + ']', file);
    }
    for (var i = 0; i < factorFiles.length; i++ ){
        let file = factorFiles[i];
        formData.append('factorFiles[' + i + ']', file);
    }
    for (var i = 0; i < actFiles.length; i++ ){
        let file = actFiles[i];
        formData.append('actFiles[' + i + ']', file);
    }
    for (var i = 0; i < otherFiles.length; i++ ){
        let file = otherFiles[i];
        formData.append('otherFiles[' + i + ']', file);
    }
    formData.append('delivery_id', deliveryId.toString());

    try {
        let response = await axios.post(ENDPOINT, formData, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },
        });

        return response.data
    } catch (e: any) {
        console.log(e.response!.data.message);
        if (e.response.data.message == "Access denied.")
            window.open("/app/auth", "_self")
    }
}

export { addDelivery, getDeliveries, getDeliveryInfo, addMoreFiles, getDeliveriesCount }