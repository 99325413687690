import { FC, useEffect, useRef, useState, createRef } from "react"
import { addClient } from "../../../../libs/clientController";
import ReactLoading from "react-loading";
import FormHook from "../../../../hooks/InputForm";
import validateEmail from "../../../../utils/emailValidator";

const AddClient: FC<{ active: boolean, setOpen: any, renderFunction: any }> = ({ active, setOpen, renderFunction }) => {
    const [sendign, setSending] = useState(false)

    const doc = { "isLoaded": useState(false), "location": useState("") }

    const forms = {
        name: FormHook((e: string) => { return e.length > 0 }),
        bin: FormHook((e: string) => { return e.length == 12 }),
        fullname: FormHook((e: string) => { return e.length > 4 }),
        position: FormHook((e: string) => { return e.length > 1 }),
        email: FormHook((e: string) => { return validateEmail(e) }),
        phone: FormHook((e: string) => { return e.length > 10 })
    }

    const handleSendButton = async () => {
        // console.log(forms.name.validationFunction())
        let check = forms.name.validationFunction()
        check &= forms.bin.validationFunction()
        check &= forms.fullname.validationFunction()
        check &= forms.position.validationFunction()
        check &= forms.email.validationFunction()
        check &= forms.phone.validationFunction()
        if (!check)
            return
        setSending(true)
        const response = await addClient({
            "client_name": forms.name.ref.current.value,
            "client_bin": forms.bin.ref.current.value,
            "contact_fullname": forms.fullname.ref.current.value,
            "contact_position": forms.position.ref.current.value,
            "contact_phone": forms.phone.ref.current.value.replace(" ","").replace("+","").replace("(", "").replace(")","").replace("-",""),
            "contact_email": forms.email.ref.current.value
        })
        setSending(false)
        setOpen(false)
        renderFunction()
    }

    if (!active)
        return <></>

    return (
        <div className="bg-black/40 w-screen h-screen fixed top-0 left-0 flex flex-col justify-start sm:items-center pt-8 sm:pt-16 z-40 overflow-y-auto">
            <div className='w-full sm:max-w-3xl px-4 sm:px-6 lg:px-8 pb-8'>
                <div className="bg-white flex flex-col p-4 rounded-xl relative">
                    <div className="text-2xl font-medium">Добавление контрагента</div>
                    <div className="mt-2 text-black/60">Заполните необходимую информацию о вашем контрагенте</div>
                    <div className="text-xl mt-6">Информация о контрагенте</div>
                    <div className="flex flex-col sm:flex-row mt-3 gap-4">
                        <div className="sm:w-1/2">
                            <label className="block font-medium leading-6 text-gray-900">Название</label>
                            <input ref={forms.name.ref} onChange={forms.name.onchange} type="text" className="w-full mt-2 bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                        </div>
                        <div className="">
                            <label className="block font-medium leading-6 text-gray-900">БИН</label>
                            <input ref={forms.bin.ref} onChange={forms.bin.onchange} type="text" className="w-full mt-2 bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                        </div>
                    </div>
                    <div className="text-xl mt-6 pt-4 border-t">Контактное лицо</div>
                    <div className="flex flex-col sm:flex-row mt-3 gap-4">
                        <div className="sm:w-3/5">
                            <label className="block font-medium leading-6 text-gray-900">ФИО</label>
                            <input ref={forms.fullname.ref} onChange={forms.fullname.onchange} type="text" className="w-full mt-2 bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                        </div>
                        <div className="sm:w-2/5">
                            <label className="block font-medium leading-6 text-gray-900">Должность</label>
                            <input ref={forms.position.ref} onChange={forms.position.onchange} type="text" className="w-full mt-2 bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row mt-2 gap-4">
                        <div className="">
                            <label className="block font-medium leading-6 text-gray-900">Контактный телефон</label>
                            <input ref={forms.phone.ref} onChange={forms.phone.onchange} type="text" className="w-full mt-2 bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                        </div>
                        <div className="sm:w-1/2">
                            <label className="block font-medium leading-6 text-gray-900">Почта</label>
                            <input ref={forms.email.ref} onChange={forms.email.onchange} type="text" className="w-full mt-2 bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                        </div>
                    </div>
                    <div className="flex flex-row mt-8 gap-x-4">
                        <button onClick={() => setOpen(false)} className="py-2 bg-gray-200 hover:bg-slate-100 duration-300 w-2/5 font-medium rounded">Отмена</button>
                        <button onClick={handleSendButton} className="py-2 bg-[#006C93] hover:opacity-80 duration-300 text-white font-medium w-3/5 rounded">Добавить</button>
                    </div>
                    {
                        sendign ?
                            <div className="absolute w-full h-full z-[99] flex flex-col top-0 left-0 justify-center items-center bg-black/20">
                                <div className="w-16 h-16">
                                    <ReactLoading type="spin" color="#fff"></ReactLoading>
                                </div>
                            </div>
                            : <></>
                    }
                </div>
            </div>
        </div>
    )
}

export default AddClient;