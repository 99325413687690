import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import AppHeader from "../../components/appHeader/AppHeader";
import { checkAndOut } from "../../libs/authContoller";
import { getPaymentsByMonth } from "../../libs/paymerntsController";
import { DateToShortTimestamp } from "../../utils/dates";
import OpenDelivery from "../deliveries/modal/openDelivery/OpenDelivery";

const Calendar = () => {
    const [payments, setPayments] = useState<Map<string, Array<any>>>()
    const [openedDelivery, setOpenedDelivery] = useState({ state: false, id: 0 })
    const [paymentsDates, setPaymentsDates] = useState<Set<string>>()

    const monthNames = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]
    const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear())
    const [currentMonth, setCurrentMonth] = useState<number>(new Date().getMonth())

    const [loading, setLoading] = useState(true)

    const firstDay = new Date(currentYear, currentMonth, 1);
    const lastDay = new Date(currentYear, currentMonth + 1, 0);
    const paddingFirstDay = firstDay.getDay() - 1 < 0 ? 6 : firstDay.getDay() - 1

    useEffect(() => {
        checkAndOut()
    }, [])

    document.title = "Календарь платежей"
    document.body.style.overflow = openedDelivery.state ? 'hidden' : 'auto'

    useEffect(() => {
    }, [currentMonth, currentYear])

    const renderMonth = async () => {
        setLoading(true)
        const response: any = await getPaymentsByMonth(currentMonth + 1, currentYear)
        const temp_dict = new Map<string, Array<Array<String>>>();
        const temp_keys = new Set<string>()
        response.data.map((element: any) => {
            element.payment_at.split(",").map((date: any) => {
                if (!temp_keys.has(date))
                    temp_dict.set(date, Array());
                temp_dict.get(date)?.push([element.delivery_id, element.client_name])
                temp_keys.add(date)
            })
        });
        setPayments(temp_dict)
        setPaymentsDates(temp_keys)
        setLoading(false)
    }

    useEffect(() => {
        renderMonth()
    }, [currentMonth])

    const handleNextMonth = () => {
        if (currentMonth == 11) {
            setCurrentMonth(0)
            setCurrentYear(year => { return year + 1 })
        } else {
            setCurrentMonth(month => { return month + 1 })
        }
    }
    const handlePreviousMonth = () => {
        if (currentMonth == 0) {
            setCurrentMonth(11)
            setCurrentYear(year => { return year - 1 })
        } else {
            setCurrentMonth(month => { return month - 1 })
        }
    }


    const docs: any = { "Реестр поставок": { "isLoaded": useState(false), "location": useState("") }, "Анкета": { "isLoaded": useState(false), "location": useState("") } }

    return (
        <div className="pb-16 bg-slate-200 min-h-screen">
            <div className="absolute top-0 left-0 w-full h-80 bg-gradient-to-br from-[#355a79] to-[#1C4B74]">
            </div>
            <header className="relative z-30">
                <AppHeader></AppHeader>
            </header>
            <main className="relative z-20">
                <div className="mx-auto w-full max-w-7xl mt-8 sm:mt-8 overflow-hidden relative px-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col sm:flex-row sm:justify-between items-start w-full gap-4">
                        <div className="flex flex-col">
                            <div className="text-4xl font-medium text-white">
                                Календарь платежей
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
                <div className="mx-auto max-w-7xl w-full flex flex-col mt-8 rounded-lg px-4 sm:px-6 lg:px-8">
                    <div className="w-full grid grid-cols-7 gap-1 sm:gap-2 bg-white px-2 sm:px-8 py-2 sm:py-6 rounded-lg overflow-hidden relative ">
                        <div className="col-span-7 flex flex-row justify-between border-b pb-2 sm:pb-4 items-center">
                            <div onClick={handlePreviousMonth} className="cursor-pointer">
                                <ChevronLeftIcon className="text-black h-6"></ChevronLeftIcon>
                            </div>
                            <div className="flex flex-col justify-center text-center">
                                <div className="text-lg sm:text-2xl">{monthNames[currentMonth]}</div>
                                <div className="text-base sm:text-lg">{currentYear}</div>
                            </div>
                            <div onClick={handleNextMonth} className="cursor-pointer">
                                <ChevronRightIcon className="text-black h-6"></ChevronRightIcon>
                            </div>
                        </div>
                        <div className="col-span-7 grid grid-cols-7 gap-x-2 py-2">
                            <div className="sm:text-xl">ПН</div>
                            <div className="text-base sm:text-xl">ВТ</div>
                            <div className="text-base sm:text-xl">СР</div>
                            <div className="text-base sm:text-xl">ЧТ</div>
                            <div className="text-base sm:text-xl">ПТ</div>
                            <div className="text-base sm:text-xl">СБ</div>
                            <div className="text-base sm:text-xl">ВС</div>
                        </div>
                        {
                            loading ?
                                <div className="bg-white col-span-7 h-96 flex flex-col justify-center items-center">
                                    <ReactLoading type="spin" color="#12436E"></ReactLoading>
                                </div>
                                : <></>
                        }
                        {
                            Array.from({ length: paddingFirstDay }, (_, i) => i + 1).map((id) => {
                                return (
                                    <div>
                                    </div>
                                )
                            })
                        }
                        {/* <div className="bg-slate-100 rounded-md p-0.5 sm:p-4 flex flex-col border-t-4 border-[#12436E]">
                            <div className="text-base sm:text-lg lg:text-2xl px-1 sm:px-0">1</div>
                        </div> */}
                        {
                            !loading ?
                                Array.from({ length: lastDay.getDate() - firstDay.getDate() + 1 }, (_, i) => i + 1).map((id) => {
                                    return (
                                        <div className="bg-slate-100 rounded-md p-1 sm:p-4 flex flex-col">
                                            <div className="text-base sm:text-lg lg:text-2xl px-1 sm:px-0">{id}</div>
                                            <div className="mt-1 sm:mt-4 space-y-0.5 sm:space-y-1">
                                                {
                                                    paymentsDates?.has(DateToShortTimestamp(currentYear, currentMonth, id).toString()) ?
                                                        payments?.get(DateToShortTimestamp(currentYear, currentMonth, id).toString())?.map((elem) => {
                                                            return (
                                                                <div key={`2 ${elem[1]}`} onClick={() => setOpenedDelivery({ state: true, id: elem[0] })} className="bg-[#f9d370] rounded py-0.5 sm:py-1 px-1 sm:px-2 text-xs sm:text-sm overflow-hidden whitespace-nowrap cursor-pointer hover:opacity-90">{elem[1]}</div>
                                                            )
                                                        }) : <></>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                                : <></>
                        }
                    </div>
                </div>
            </main>
            <OpenDelivery deliveryStates={openedDelivery} setDeliveryStates={setOpenedDelivery}></OpenDelivery>
        </div>
    )
}

export default Calendar;