import { FC, useState, useEffect, useRef } from "react";
import { PaperClipIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline"
import { DateToShortTimestamp, ShortTimestampToString } from "../../../../utils/dates";
import { addContract } from "../../../../libs/contractsController";
import FormHook from "../../../../hooks/InputForm";
import ReactLoading from "react-loading";

const AddContract: FC<{ active: boolean, setOpen: any, client_id: string }> = ({ active, setOpen, client_id }) => {

    const forms = {
        name: FormHook((e: string) => { return e.length > 0 }),
        cost: FormHook((e: string) => { return Number(e.split(" ").join("")) > 0 }),
    }

    const [clientId, setClientId] = useState(client_id)

    const [contractCost, setContractCost] = useState(0)
    const [isOpenSelectionClient, setOpenSelectionClient] = useState(false)
    const [isDateSigningOpen, setOpenDateSigning] = useState(false)
    const [isDateEndingOpen, setOpenDateEnding] = useState(false)

    const [signingDate, setSigningDate] = useState(DateToShortTimestamp(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))
    const [endingDate, setEndingDate] = useState(DateToShortTimestamp(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))

    const [uploadingFiles, setUploadingFiles] = useState(false)

    const handleSendButton = async () => {
        let check = forms.name.validationFunction()
        check &= forms.cost.validationFunction()

        if (!check || contractFiles.length == 0)
            return
        setUploadingFiles(true)
        const response = await addContract(contractFiles, client_id, forms.name.ref.current.value, signingDate.toString(), Number(forms.cost.ref.current.value.split(" ").join("")), endingDate.toString())
        setUploadingFiles(false)
        setOpen(false)
    }

    const handleCancel = () => {
        setClientId("")
        setContractFiles([])
        setSigningDate(DateToShortTimestamp(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))
        setEndingDate(DateToShortTimestamp(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))
        setOpen(false)
    }

    const [contractFiles, setContractFiles] = useState<any>([]);

    const formatNumber = (value: string) => {
        return value.split("").reverse().join("").replace(/(.{3})/g, "$1 ").split("").reverse().join("").trim()
    }

    useEffect(() => {
        if (active) {
            setClientId(client_id)
            setContractFiles([])
            setSigningDate(DateToShortTimestamp(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))
            setEndingDate(DateToShortTimestamp(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))
        }
    }, [active])

    if (!active)
        return <></>

    return (
        <div className="bg-black/40 w-screen h-screen fixed top-0 left-0 flex flex-col justify-start sm:items-center pt-8 sm:pt-0 sm:justify-center z-40 overflow-y-auto">
            <div className='w-full sm:max-w-2xl px-6 sm:px-8 lg:px-10 pb-8'>
                <div className="bg-white p-4 rounded-xl flex flex-col sm:grid sm:grid-cols-4 gap-2 relative overflow-hidden">
                    <div className="text-2xl font-medium col-span-4">Добавление договора</div>
                    {/* <div className="mt-2 text-black/60 col-span-4">Данный договор будет отправлено оппоненту для подтверждения</div> */}
                    <div className="mt-4 col-span-4 relative">
                        <label className="block font-medium leading-6 text-gray-900 mt-4 relative">Наименование договора</label>
                        <input ref={forms.name.ref} onChange={forms.name.onchange} type="text" className="relative w-full mt-2 bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                    </div>
                    <div className="col-span-1">
                        <label className="block font-medium leading-6 text-gray-900 mt-2">Дата заключения</label>
                        <div onClick={() => setOpenDateSigning(true)} className="h-9 w-full mt-2 bg-gray-100 block flex-1 border-0 rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6">
                            <div className="text-sm">
                                {
                                    ShortTimestampToString(signingDate)
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-span-2 sm:ml-8">
                        <label className="block font-medium leading-6 text-gray-900 mt-2">Сумма договора</label>
                        <input value={formatNumber(contractCost.toString())} ref={forms.cost.ref} onChange={e => {setContractCost(isNaN(Number(e.target.value.split(" ").join(""))) ? Number(e.target.value.slice(0, -1).split(" ").join("")) : Number(e.target.value.split(" ").join(""))); forms.cost.onchange()}} type="text" className="w-full mt-2 bg-gray-100 block flex-1 border-1 border-transparent focus:border-transparent rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6" />
                        {
                            // paymentDates.length > 1 ?
                            //     <div className="text-sm text-black/60 mt-1">
                            //         Размер одного платежа: {(deliveryCost/paymentDates.length).toFixed(2)}
                            //     </div>
                            //     : <></>
                        }

                    </div>
                    <div className="col-span-1"></div>
                    <div className="col-span-1">
                        <label className="block font-medium leading-6 text-gray-900 mt-2">Дата окончания</label>
                        <div onClick={() => setOpenDateEnding(true)} className="h-9 w-full mt-2 bg-gray-100 block flex-1 border-0 rounded py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6">
                            <div className="text-sm">
                                {
                                    ShortTimestampToString(endingDate)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 text-xl border-t pt-4 col-span-4">
                        {/* <label className="block font-medium leading-6 text-gray-900 text-xl border-t pt-4">Документ</label> */}
                        <div className="grid grid-cols-1 gap-4 grid grid-cols-2">
                            <Document2 name="Договор" doc_info="не помню" files={contractFiles} setFiles={setContractFiles}></Document2>
                        </div>
                    </div>
                    <div className="flex flex-row mt-10 gap-x-4 col-span-4">
                        <button disabled={uploadingFiles} onClick={handleCancel} className="py-2 bg-gray-200 hover:bg-slate-100 duration-300 w-2/5 font-medium rounded">Отмена</button>
                        <button disabled={uploadingFiles} onClick={handleSendButton} className="py-2 bg-[#006C93] hover:opacity-80 duration-300 text-white font-medium w-3/5 rounded">Добавить</button>
                    </div>
                    {
                        uploadingFiles ?
                            <div className="absolute w-full h-full z-[99] flex flex-col top-0 left-0 justify-center items-center bg-black/20">
                                <div className="w-16 h-16">
                                    <ReactLoading type="spin" color="#fff"></ReactLoading>
                                </div>
                            </div>
                            : <></>
                    }
                </div>
            </div>
            <Datepicker active={isDateSigningOpen} setOpen={setOpenDateSigning} selectedDate={signingDate} setDate={setSigningDate}></Datepicker>
            <Datepicker active={isDateEndingOpen} setOpen={setOpenDateEnding} selectedDate={endingDate} setDate={setEndingDate}></Datepicker>
        </div>
    )
}

export default AddContract

const Document2: FC<{ doc_info: any, name: string, files: any, setFiles: any }> = ({ doc_info, name, files, setFiles }) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleUploadClick = () => {
        inputRef.current?.click();
    }

    const handleUploadFile = (elem: any) => {
        console.log(elem.target.files);
        setFiles(elem.target.files)
    }

    useEffect(() => {
        console.log(files)
    }, [files])

    return (
        <div>
            <div className="flex flex-row text-base items-center justify-start">
                <div className="font-medium">{name}</div>
                <button className="ml-2 text-blue-600" onClick={handleUploadClick}>{files.length == 0 ? "Добавить" : "Изменить"}</button>
            </div>
            <input type="file" multiple ref={inputRef} className="hidden absolute" onChange={e => handleUploadFile(e)} />
            <div className="flex flex-col">

                {
                    Array.from(files).map((elem: any) => {
                        return (
                            <div key={`Document ${elem.name}`} className="bg-white border rounded text-base px-4 py-1 mt-2 flex flex-row justify-between items-center">
                                <div className="flex flex-row items-center font-medium">
                                    <PaperClipIcon className="h-5 pr-2 text-blue-500"></PaperClipIcon>
                                    {elem.name}
                                </div>
                                {/* <div>
                                    <XIcon className="h-5 text-black/60"></XIcon>
                                </div> */}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

const Datepicker: FC<{ active: boolean, setOpen: any, selectedDate: Number, setDate: any }> = ({ active, setOpen, selectedDate, setDate }) => {
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    const [firstDay, setFirstDay] = useState(new Date(y, m, 1))
    const [lastDay, setLastDay] = useState(new Date(y, m + 1, 1))
    const [currentMonth, setCurrentMonth] = useState(date.getMonth())
    const [currentYear, setCurrentYear] = useState(date.getFullYear())
    const [dates, setDates] = useState<Number>(selectedDate)

    const month = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]

    useEffect(() => {
        setDates(selectedDate)
    }, [selectedDate])

    const handleBackMonth = () => {
        if (currentMonth == 0) {
            setCurrentYear(currentYear - 1)
            setCurrentMonth(11)
        } else {
            setCurrentMonth(currentMonth - 1)
        }

    }

    const handleNextMonth = () => {
        if (currentMonth == 11) {
            setCurrentYear(currentYear + 1)
            setCurrentMonth(0)
        } else {
            setCurrentMonth(currentMonth + 1)
        }
    }

    useEffect(() => {
        setFirstDay(new Date(currentYear, currentMonth, 1))
        setLastDay(new Date(currentYear, currentMonth + 1, 1))
        console.log(currentMonth, currentYear)
    }, [currentYear, currentMonth])

    const handleSelectDate = (elem: any) => {
        if (elem.target.classList.contains('bg-transparent')) {
            elem.target.classList.replace('bg-transparent', 'bg-[#12436E]')
            elem.target.classList.replace('text-black', 'text-white')
            const additionalDate = (Date.parse(`${currentYear}-${(currentMonth + 1).toString().padStart(2, '0')}-${elem.target.innerHTML.padStart(2, '0')}T00:00:00.000Z`) / 100000)
            setDates(additionalDate)
            setDate(additionalDate)
        }
        setOpen(false)
    }

    if (!active)
        return (<></>)

    return (
        <>
            <div className="fixed top-0 left-0 w-screen h-screen bg-black/40 flex flex-col justify-start items-center">
                <div className="max-w-md bg-white w-full mt-32 p-4 rounded-lg">
                    <div className="bg-slate-100 rounded-md text-xl flex flex-row justify-between py-2 px-2 items-center">
                        <ChevronLeftIcon onClick={handleBackMonth} className="text-black h-6"></ChevronLeftIcon>
                        <div className="flex flex-col items-center">
                            <div>{month[currentMonth]}</div>
                            <div className="text-sm">{currentYear}</div>
                        </div>
                        <ChevronRightIcon onClick={handleNextMonth} className="text-black h-6"></ChevronRightIcon>
                    </div>
                    <div className="grid grid-cols-7 mt-6 gap-2">
                        {
                            ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"].map((elem) => {
                                return (
                                    <div className="text-center">
                                        {elem}
                                    </div>
                                )
                            })
                        }
                        {
                            Array.from(Array(firstDay.getDay() - 1 < 0 ? 6 : firstDay.getDay() - 1).keys()).map(() => {
                                return (<div></div>)
                            })
                        }
                        {
                            Array.from(Array((lastDay.getTime() / (24 * 60 * 60) - firstDay.getTime() / (24 * 60 * 60)) / 1000).keys()).map((elem) => {
                                if (dates != DateToShortTimestamp(currentYear, currentMonth, elem + 1))
                                    return (<div key={elem + 1} onClick={(e) => { handleSelectDate(e) }} className="text-black duration-300 text-center border font-medium rounded-md py-1 cursor-pointer bg-transparent">{elem + 1}</div>)
                                else
                                    return (<div key={elem + 1} onClick={(e) => { handleSelectDate(e) }} className="text-white duration-300 text-center border font-medium rounded-md py-1 cursor-pointer bg-[#12436E]">{elem + 1}</div>)
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}