const DateToShortTimestamp = (year: number, month: number, day: number) => {
    return (Date.parse(`${year}-${(month + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T00:00:00.000Z`) / 100000)
}

const ShortTimestampToString = (value: number) => {
    const date = new Date(value * 100000)
    const month = ["Января", "Февраля", "Марта", "Апреля", "Мая", "Июня", "Июля", "Августа", "Сентября", "Октября", "Ноября", "Декабря"]
    return `${date.getDate()} ${month[date.getMonth()]} ${date.getFullYear()}`
}

export { DateToShortTimestamp, ShortTimestampToString }